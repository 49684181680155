var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list-empty" },
    [
      _vm.action
        ? [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("t")("common.noAny")) +
                " " +
                _vm._s(_vm.name ? _vm.name : _vm.$t("components.data")) +
                "，"
            ),
            _c(
              "span",
              {
                staticClass: "action-text",
                on: {
                  click: function ($event) {
                    return _vm.$emit("action")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.action))]
            ),
          ]
        : [
            _vm._v(
              _vm._s(_vm._f("t")("common.noAny")) +
                " " +
                _vm._s(_vm.name ? _vm.name : _vm.$t("components.data"))
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }