/**
 * Dmartech 前端部署配置
 */

const LINKS = {};
switch (process.env.NODE_ENV) {

    /**
     * dev 服务器
     */
    case 'dev':
        LINKS.ORIGIN = 'http://app-beta.qdum.com';
        LINKS.WEB_BASE = '';
        LINKS.ADMIN_ORIGIN = 'http://admin-beta.qdum.com';
        LINKS.GATE_ORIGIN = 'http://dmartech.qdum.com';
        LINKS.REALY_API_BASE = 'https://gate-beta.dmartech.cn/relay';
        LINKS.JOURNEY_API_BASE = 'http://dmartech.qdum.com/journey';
        LINKS.REAL_TIME_JOURNEY_API_BASE = 'http://dmartech.qdum.com/rtjourney';
        LINKS.JOURNEY_MATERIAL_API_BASE = 'http://dmartech.qdum.com/material';
        LINKS.SMS_API_BASE = 'http://dmartech.qdum.com/smsservice';
        LINKS.SCRM_API_BASE = 'http://dmartech.qdum.com/scrm';
        LINKS.CONTACT_API_BASE = 'http://dmartech.qdum.com/contact';
        LINKS.WECHAT_API_BASE = 'http://dmartech.qdum.com/wechat';
        LINKS.WEB_SERVICE_API_BASE = 'http://dmartech.qdum.com/webservice';
        LINKS.ETL_API_BASE = 'http://dmartech.qdum.com/etl';
        LINKS.QUERY_API_BASE = 'http://dmartech.qdum.com/query';
        LINKS.MANAGE_API_BASE = 'https://gate-beta.dmartech.cn/manage';
        LINKS.QUESTIONNAIRE_API_BASE = 'http://dmartech.qdum.com/questionnaire';
        LINKS.SCHEDULE_API_BASE = 'http://dmartech.qdum.com/schedule';
        LINKS.NIKE_API_BASE = 'http://dmartech.qdum.com/journey';
        LINKS.NIKE_MATERIAL_API_BASE = 'http://dmartech.qdum.com/material';
        break;

    /**
     * beta 服务器
     */
    case 'beta':
        LINKS.ORIGIN = 'http://scrm-beta.freemore.cn/';
        LINKS.WEB_BASE = '';
        LINKS.ADMIN_ORIGIN = 'https://admin-beta.dmartech.cn';
        LINKS.GATE_ORIGIN = 'https://test.freemore.cn/gateway';
        LINKS.REALY_API_BASE = 'https://test.freemore.cn/gateway/relay';
        LINKS.JOURNEY_API_BASE = 'https://test.freemore.cn/gateway/journey';
        LINKS.REAL_TIME_JOURNEY_API_BASE = 'https://test.freemore.cn/gateway/rtjourney';
        LINKS.JOURNEY_MATERIAL_API_BASE = 'https://test.freemore.cn/gateway/material';
        LINKS.SMS_API_BASE = 'http://gate.dmartech.cn/smsservice';
        LINKS.SCRM_API_BASE = 'https://test.freemore.cn/gateway/scrm';
        LINKS.CONTACT_API_BASE = 'https://test.freemore.cn/gateway/contact';
        LINKS.WECHAT_API_BASE = 'https://test.freemore.cn/gateway/wechat';
        LINKS.WEB_SERVICE_API_BASE = 'https://test.freemore.cn/gateway/webservice';
        LINKS.ETL_API_BASE = 'https://test.freemore.cn/gateway/etl';
        LINKS.QUERY_API_BASE = 'https://test.freemore.cn/gateway/query';
        LINKS.MANAGE_API_BASE = 'https://test.freemore.cn/gateway/manage';
        LINKS.QUESTIONNAIRE_API_BASE = 'https://test.freemore.cn/gateway/questionnaire';
        LINKS.SCHEDULE_API_BASE = 'https://test.freemore.cn/gateway/schedule';
        LINKS.NIKE_API_BASE = 'https://test.freemore.cn/gateway/journey';
        LINKS.NIKE_MATERIAL_API_BASE = 'https://test.freemore.cn/gateway/material';
        break;


    /**
     * 生产服务器
     */
    case 'production':
        LINKS.ORIGIN = 'https://scrm.freemore.cn';
        LINKS.WEB_BASE = '';
        LINKS.ADMIN_ORIGIN = 'https://scrm.freemore.cn/admin';
        LINKS.GATE_ORIGIN = 'https://gate.freemore.cn';
        LINKS.REALY_API_BASE = 'https://gate.freemore.cn/relay';
        LINKS.JOURNEY_API_BASE = 'https://gate.freemore.cn/journey';
        LINKS.REAL_TIME_JOURNEY_API_BASE = 'https://gate.freemore.cn/rtjourney';
        LINKS.JOURNEY_MATERIAL_API_BASE = 'https://gate.freemore.cn/material';
        LINKS.SMS_API_BASE = 'https://gate.dmartech.cn/smsservice';
        LINKS.SCRM_API_BASE = 'https://gate.freemore.cn/scrm';
        LINKS.CONTACT_API_BASE = 'https://gate.freemore.cn/contact';
        LINKS.WECHAT_API_BASE = 'https://gate.freemore.cn/wechat';
        LINKS.WEB_SERVICE_API_BASE = 'https://gate.freemore.cn/webservice';
        LINKS.ETL_API_BASE = 'https://gate.freemore.cn/etl';
        LINKS.QUERY_API_BASE = 'https://gate.freemore.cn/query';
        LINKS.MANAGE_API_BASE = 'https://gate.freemore.cn/manage';
        LINKS.QUESTIONNAIRE_API_BASE = 'https://gate.freemore.cn/questionnaire';
        LINKS.SCHEDULE_API_BASE = 'https://gate.freemore.cn/schedule';
        LINKS.NIKE_API_BASE = 'https://gate.freemore.cn/journey';
        LINKS.NIKE_MATERIAL_API_BASE = 'https://gate.freemore.cn/material';
        break;

    /**
     * 本地开发
     */
    default:
        LINKS.ORIGIN = 'http://scrm-beta.freemore.cn/';
        LINKS.WEB_BASE = '';
        LINKS.ADMIN_ORIGIN = 'https://admin-beta.dmartech.cn';
        LINKS.GATE_ORIGIN = 'https://test.freemore.cn/gateway';
        LINKS.REALY_API_BASE = 'https://test.freemore.cn/gateway/relay';
        LINKS.JOURNEY_API_BASE = 'https://test.freemore.cn/gateway/journey';
        LINKS.REAL_TIME_JOURNEY_API_BASE = 'https://test.freemore.cn/gateway/rtjourney';
        LINKS.JOURNEY_MATERIAL_API_BASE = 'https://test.freemore.cn/gateway/material';
        LINKS.SMS_API_BASE = 'http://gate.dmartech.cn/smsservice';
        LINKS.SCRM_API_BASE = 'https://test.freemore.cn/gateway/scrm';
        LINKS.CONTACT_API_BASE = 'https://test.freemore.cn/gateway/contact';
        LINKS.WECHAT_API_BASE = 'https://test.freemore.cn/gateway/wechat';
        LINKS.WEB_SERVICE_API_BASE = 'https://test.freemore.cn/gateway/webservice';
        LINKS.ETL_API_BASE = 'https://test.freemore.cn/gateway/etl';
        LINKS.QUERY_API_BASE = 'https://test.freemore.cn/gateway/query';
        LINKS.MANAGE_API_BASE = 'https://gate.freemore.cn/manage';
        LINKS.QUESTIONNAIRE_API_BASE = 'https://test.freemore.cn/gateway/questionnaire';
        LINKS.SCHEDULE_API_BASE = 'https://test.freemore.cn/gateway/schedule';
        LINKS.NIKE_API_BASE = 'https://test.freemore.cn/gateway/journey';
        LINKS.NIKE_MATERIAL_API_BASE = 'https://test.freemore.cn/gateway/material';
        break;
}

module.exports = LINKS;
