<template>
    <backdrop>
        <div class="modal" :class="size">
            <div class="modal-head">
                <button type="button" class="pull-right modal-closer" @click.stop="close">
                    <svg aria-hidden="true" v-if="!readonly">
                        <use xlink:href="#icon-close"></use>
                    </svg>
                </button>
                <span :title="title">{{title}}</span>
            </div>
            <div class="modal-content" :class="{neat}">
                <slot></slot>
            </div>
            <div class="modal-panel" v-if="$slots.buttons">
                <slot name="buttons"></slot>
            </div>
        </div>
    </backdrop>
</template>
<script type="text/ecmascript-6">

    const modalStack = [];

    export default {
        name: 'modal',
        props: {
            size: {
                type: String,
                'default': 'md' //  'md', 'lg'
            },
            neat: Boolean,
            title: {
                type: String,
                required: true
            },
            readonly: Boolean
        },
        mounted() {
            modalStack.push(this);
            window.addEventListener('keydown', this.escape);
        },
        beforeDestroy() {
            window.removeEventListener('keydown', this.escape);
        },
        methods: {
            close() {
                this.$emit('close');
            },
            escape(e) {
                if (e.keyCode === 27) {
                    let targetModal = modalStack.pop();
                    targetModal && targetModal.close();
                }
            }
        }
    };
</script>
<style lang="scss" type="text/scss" rel="stylesheet/scss">

    @import "../../scss/base/variables";
    @import "../../scss/base/mixin";

    .modal {
        margin: 10vh auto;
        background-color: white;
        border: 1px solid $border-color;
        @include border-radius(4px);

        &.sm {
            width: 400px;
        }

        &.md {
            width: 600px;
        }

        &.lg {
            width: 1000px;
        }
        // super large 额外加大
        &.sl {
            width: 1125px;
        }

        .modal-head {
            height: $modal-head-height - 1px;
            line-height: $modal-head-height - 1px;
            position: relative;
            border-bottom: 1px solid $border-color;
            text-align: left;
            @include ellipsis();
            color: $color-title;
            font-size: 16px;

            span {
                margin-left: 13px;
            }

            .modal-closer {
                position: relative;
                width: 16px;
                height: 16px;
                padding: 3px;
                margin-right: 11px;
                margin-top: ($modal-head-height - 16px)/2;
                border: none;
                overflow: hidden;
                background-color: transparent;
                cursor: pointer;
                fill: $color-light-content;

                &:hover {
                    fill: $color-title;
                }
            }

            svg {
                display: block;
                height: 100%;
                width: 100%;
            }
        }

        .modal-content {
            padding: 24px 13px;

            &.neat {
                position: relative;
                padding: 0;
            }
        }

        .modal-panel {
            padding: 0 16px;
            text-align: right;
            height: $modal-panel-height;
            line-height: $modal-panel-height;
            border-top: 1px solid $border-color;
        }
    }

</style>
