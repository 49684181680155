var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "blur",
          rawName: "v-blur",
          value: _vm.cancel,
          expression: "cancel",
        },
      ],
      staticClass: "inline-confirm",
      class: { static: _vm.staticPositioned },
    },
    [
      _c("i", { ref: "tail", staticClass: "tail" }),
      _vm._v(" "),
      _c("div", {
        staticClass: "text",
        attrs: { title: _vm.text },
        domProps: { textContent: _vm._s(_vm.text) },
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-theme-border",
          attrs: { type: "button" },
          on: { click: _vm.cancel },
        },
        [_vm._v(_vm._s(_vm._f("t")("common.cancel")))]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-theme",
          attrs: { type: "button" },
          on: { click: _vm.confirm },
        },
        [_vm._v(_vm._s(_vm._f("t")("common.confirm")))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }