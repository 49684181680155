/**
 * Created by naeemo on 2017/3/9.
 */

import Model from 'web-model';
import LINKS from '../../LINKS';

export default new Model({
    base: LINKS.CONTACT_API_BASE + '/api/contact/v1',
    api: {
        
        /**
         * 获取字段列表
         */
        getFields() {
            return this.request
                .get('/field');
        },
        
        
        /**
         * 创建新字段
         * @param field field, fieldCn, fieldType
         * @return {Request|*}
         */
        create(field) {
            return this.request
                .post('/field')
                .send(field);
        },
        
        
        /**
         * 更新字段
         * @param field
         * @return {Request|*}
         */
        update(field) {
            return this.request
                .put(`/field/${field.id}`)
                .send(field);
        },
        
        
        /**
         * 删除一个字段
         * @param id
         * @return {*|Request}
         */
        del(id) {
            return this.request
                .del(`/field/${id}`);
        },
        
        
        /**
         * 自定义字段
         * @param
         */
        customerField(status) {
            return this.request
                .get('/customerFieldList')
                .query({status});
        },
    }
});
