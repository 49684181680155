/**
 * Created by lvjuan on 2019/8/26.
 */
import Model from 'web-model';
import LINKS from '../../LINKS';

export default new Model({
    base: LINKS.GATE_ORIGIN + '/api/',
    api: {
        /**
         *
         */
        getAllDepartment() {
            return this.request
                .get('v1/department/getAllDepartment');
        },
        /**
         * 查看发送通道列表
         * @param departmentId
         * @param searchType
         */
        getAllSendChannel({departmentId, searchType}) {
            return this.request
                .get('getAllSendChannel')
                .query({departmentId, searchType});
        },

        /**
         * 保存发送通道
         * @param departmentId  部门id
         * @param sendAddress   发件地址
         * @param sendType      0邮件 1短信 2彩信
         * @param senderId      邮件活动号
         * @param sendoutId     短信活动号
         * @param message       短信签名
         * @param senderList    发件人列表 sendType=0时，需要传参
         */
        saveSendChannel({departmentId, sendAddress, sendType, senderId, sendoutId, message, senderList}) {
            return this.request
                .post('saveSendChannel')
                .send({departmentId, sendAddress, sendType, senderId, sendoutId, message, senderList});
        },
        deleteSendChannel({id}) {
            return this.request
                .delete(`deleteSendChannel`)
                .query({id});
        },
        /**
         * 查询发件人列表接口，系统+员工自己的
         * @param sendAddressId     发件地址 id
         * @returns {*}
         */
        getSenderAll(sendAddressId) {
            return this.request
                .get(`sender/getSenderAll`)
                .query({sendAddressId});
        },
        /**
         * 根据部门id和发件地址id查询发件人列表（管理员配置的）
         * @param departmentId
         * @param sendAddressId
         * @returns {*}
         */
        getSenderByDepIdSendId(departmentId, sendAddressId) {
            return this.request
                .get(`sender/getSenderByDepIdSendId`)
                .query({departmentId, sendAddressId});
        },
        /**
         * 单条保存发件人
         * @param departmentId      部门 id
         * @param name              发件人 name
         * @param sendAddressId     发件地址 id，senderType=1时必填
         * @param senderType        类型 1=管理员配置 2=员工自定义
         * @returns {*}
         */
        saveEmailSender({departmentId, name, sendAddressId, senderType}) {
            return this.request
                .post(`sender/sender`)
                .send({departmentId, name, sendAddressId, senderType});
        },
        /**
         * 单条编辑发件人
         * @param id     发件人 id
         * @param name   发件人 name
         * @returns {*}
         */
        modifyEmailSender({id, name}) {
            return this.request
                .put(`sender/sender`)
                .send({id, name});
        },
        /**
         * 邮件-删除发件人
         * @param id
         * @returns {*}
         */
        delEmailSender(id) {
            return this.request
                .delete(`sender/sender/${id}`);
        }
    }
});

