var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.open,
          expression: "open",
        },
      ],
      staticClass: "tip",
      class: [_vm.direction, { line: _vm.line, warn: _vm.warn }],
      domProps: { innerHTML: _vm._s(_vm.message) },
      on: { mouseenter: _vm.show, mouseleave: _vm.leave },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }