/**
 * webpack entry
 */

import '@babel/polyfill';
import Vue from 'vue';
import Vuex from 'vuex';
import VueRouter from 'vue-router';
import {defaultRoutes, getRoutesByMenuRecords} from './routes/index.js';
import Plugins from './common/vue-plugin';
import I18N, {getFirstBrowserLanguage, I18N_STORE_NAME, LANGUAGE_CHANGE, translate} from './common/i18n/i18n';
import Model from 'web-model';
import rootStore from './common/vuex/rootStore';
import {logError, trackEvent} from './monitor';
import {CLEAR_USER_INFO, SET_AUTH_LOCK, SET_PLUGIN_ROUTES} from './common/vuex/mutationTypes';
import {
    LOAD_LANGUAGE,
    RECOVER_LANGUAGE,
    RECOVER_USER_INFO,
    SIGNED_IN,
    SIGNED_OUT,
    UPDATE_ACCOUNT_BALANCE
} from './common/vuex/actionTypes';
import './common/scss/index.scss';
import './common/fonts/iconfont';
import VueClipboard from 'vue-clipboard2';
import {hwCountRender} from './render.js';
import VueTelInput from 'vue-tel-input';
import {PRIVATE_DEVELOPMENT} from './private';

// 是否是私有化部署
console.info(PRIVATE_DEVELOPMENT ? 'Private' : 'Dmartech');

Vue.use(VueClipboard);
Vue.use(VueTelInput);

// Vue 开发选项
if (process.env.NODE_ENV !== 'production') {
    Vue.config.devtools = true;
    Vue.config.performance = true;
    Vue.config.productionTip = false;
}
if (process.env.NODE_ENV !== 'development') {
    Vue.config.errorHandler = (err, vm, info) => {
        // handle error
        // `info` is a Vue-specific error info, e.g. which lifecycle hook
        // the error was found in. Only available in 2.2.0+
        logError(`<${vm.name}/> ${info} ${err}`);
    };
    window.onerror = logError;
}

/**
 * Vue 公用方法、插件
 */
Vue.use(Plugins);


/**
 * vuex
 */
Vue.use(Vuex);
const store = new Vuex.Store(rootStore);


/**
 * I18n
 */
Vue.use(I18N);
// detect the language change
if ('onlanguagechange' in window) {
    window.onlanguagechange = function () {
        const newLang = getFirstBrowserLanguage();
        if (newLang !== store.state[I18N_STORE_NAME].lang) {
            store.dispatch(LOAD_LANGUAGE, newLang);
        }
    };
}


/**
 * 浏览器多个 tab 页、窗口间的通信
 * localStorage event listener
 */
window.addEventListener('storage', function (e) {
    switch (e.key) {
        // 其他 tab 登出
        case SIGNED_OUT:
            store.commit(CLEAR_USER_INFO);
            store.commit(SET_AUTH_LOCK, {
                title: translate('common.needLogin'),
                message: translate('common.reLogin')
            });
            break;
        // 其他 tab 登录
        case SIGNED_IN:
            store.dispatch(SIGNED_IN);
            break;
        // 切换语言
        case LANGUAGE_CHANGE:
            if (e.newValue) {
                store.dispatch(LOAD_LANGUAGE, e.newValue);
            }
            break;
    }
});


/**
 * vue-router 路由配置
 */
Vue.use(VueRouter);
const router = new VueRouter({
    mode: 'history',
    routes: defaultRoutes
});
// 路由拦截器
router.beforeEach((to, from, next) => {
    // 访问的路由是否需要登录权限
    const tokenNeeded = to.matched.some(r => r.meta.auth);
    // 登录权限（有没有 JWT）
    const noToken = store.state.user.authorization === '';
    // 菜单权限
    const noMenu = !store.state.user.menus || Object.keys(store.state.user.menus) === 0;
    // 访问的路由是否需要微信公众号授权
    const wechatOfficialAccountNecessary = to.matched.some(matchRoute => matchRoute.meta.officialAccountNecessary);
    // 访问的路由是否有菜单权限
    const authMenu = !noMenu && (store.state.user.menus[to.name] || store.state.user.menus[to.meta.auth]);
    // 是否开启两步验证
    const twoFactorOpen = store.state.user.twoFactorOpen;
    // （短信验证码）验证是否通过
    const passTwoFactorOpen = store.state.user.passTwoFactorOpen;

    let menuNotAuthorized = '';
    if (!store.state.user.roleId) {
        menuNotAuthorized = authMenu && authMenu.type !== 2 && authMenu.parentId !== 0;
    } else {
        menuNotAuthorized = authMenu && authMenu.showType === 0;
    }
    let params = window.location.href.split('?')[1];
    // 华为单点登录判断
    if (tokenNeeded && (noToken || noMenu) && to.path === '/home' && params) {
        hwCountRender(store, router, params);
        return;
    }

    // 两步验证开启 && 验证码未通过 =>  登录页面
    if (twoFactorOpen && !passTwoFactorOpen) {
        to.name === 'login' ? next() : next({name: 'login'});
        return;
    }

    if (tokenNeeded && (noToken || noMenu)) {
        // 1. 没有登录信息，或者没有任何菜单权限，跳转登录
        console.info(`没有token, 访问需要权限的视图: ${to.name}, 跳转到登录`);
        to.name === 'login' ? next() : next({name: 'login'});
    } else if (!store.state.user.authorizerInfoId && wechatOfficialAccountNecessary && !menuNotAuthorized) {
        // 2. 未绑定微信公众号，跳转至引导绑定的页面
        let {href} = router.resolve(Object.assign({}, to));
        next({name: 'bindWechatOfficialAccount', query: {redirect: href}});
    } else {
        // all checks pass
        document.title = to.meta.name ? 'Dmartech - ' + translate(to.meta.name) : 'Dmartech';
        if (to.meta.name) trackEvent(translate(to.meta.name));
        next();
    }
});
// router.afterEach(route => {});


// recover previous language setting, navigator.language as default.
// this recover is an async action
store.dispatch(RECOVER_LANGUAGE)
    .then(() => {
        /**
         * API接口拦截器
         */
        let errorCount = 0;
        Model.use({
            beforeEach: function (next) {
                if (errorCount >= 3) {
                    console.warn('请求数据多次异常，请检查网络连接、后台服务状态是否正常。');
                }

                // bear the jwt token
                if (store.state.user.authorization) {
                    let token = 'Bearer ' + store.state.user.authorization;
                    window.localStorage.setItem('token', token);
                    this.set('Authorization', token);
                    this.set('wechat_id', store.state.user.authorizerInfoId); // 微信
                    this.set('app_id', store.state.user.appId); // 微信
                    // i18n setting
                    this.set('Accept-Language', store.state[I18N_STORE_NAME].lang);
                    next();
                } else {
                    next(false);
                    console.info(`没有token访问需要权限的接口: ${this.url}`);
                    store.commit(SET_AUTH_LOCK, {
                        title: translate('common.needLogin'),
                        message: translate('common.needToLogin')
                    });
                }
            },
            afterEach: function (err, res) {
                // 请求异常
                if (err) {
                    errorCount++;

                    // 登录信息过期
                    if (err.status === 401) {
                        store.commit(CLEAR_USER_INFO);
                        store.commit(SET_AUTH_LOCK, {
                            title: translate('common.needLogin'),
                            message: translate('common.expired')
                        });
                    } else {
                        // 状态码
                        let statusCode = res && res.body && res.body.code
                            ? res.body.code
                            : err.status;
                        // 异常信息字符串
                        let message = `[${statusCode}] ${this.method.toUpperCase()} ${this.url}`;

                        if (statusCode === 403) {
                            console.info(`访问接口权限不够: ${this.url}`);
                            store.commit(SET_AUTH_LOCK, {
                                title: translate('common.needAuth'),
                                message: translate('common.noAuth') + `<blockquote><code>${this.method}</code><br><code>${this.url}</code></blockquote>`
                            });
                        } else {
                            if (res && res.body && res.body.message) {
                                message += res.body.message;
                                app.$toast(res.body.message, 'warn');
                            }
                        }

                        logError(message);
                    }

                    return false;
                }

                // 请求正常，返回的数据在业务上不合理，code !== 200 表征不合理
                if (!res || !res.body || !res.body.code) return;
                if (res.body.code !== 200) {
                    let message = `[${res.body.code}] ${this.method.toUpperCase()} ${this.url} ${res.body.message}`;
                    logError(message);
                    app.$toast(res.body.message, 'warn');
                    return false;
                }

                // 请求正常，数据正常，重置接口报错记录
                errorCount = 0;
            }
        });

        /**
         * 恢复状态 & 更新数据
         */
        if (!store.state.user.authorization) {
            // 立即恢复用户信息
            store.dispatch(RECOVER_USER_INFO);

            if (store.state.user.authorization) {

                // 更新账户余量
                store.dispatch(UPDATE_ACCOUNT_BALANCE);

                // todo 更新菜单权限？更新公众号绑定信息？

                // 客服插件配置 http://developer.kf5.com/widgets/supportbox/api/#api-functions-identify
                if (window.KF5SupportBoxAPI) {
                    window.KF5SupportBoxAPI.ready(function () {
                        // 传递用户信息给组件使用
                        window.KF5SupportBoxAPI.identify({
                            name: store.state.user.userName,
                            email: store.state.user.email,
                            // 自定义用户信息
                            metadata: [
                                {name: 'id', value: store.state.user.id},
                                {name: 'cid', value: store.state.user.cid},
                                {name: '是否开通微信', value: store.state.user.isWx},
                                {name: '套餐名称', value: store.state.user.mealName},
                                {name: '套餐有效期', value: store.state.user.validTime},
                                {name: '短信余量', value: store.state.user.smsSurplus},
                                {name: '彩信余量', value: store.state.user.mmsSurplus}
                            ]
                        });
                    });
                }

                // 根据菜单权限注册路由
                if (store.state.user.menus && Object.keys(store.state.user.menus).length) {
                    let [routes, pluginRoutes] = getRoutesByMenuRecords(store.state.user.menus);
                    router.addRoutes(routes);
                    store.commit(SET_PLUGIN_ROUTES, pluginRoutes);
                }
            }
        }

        /**
         * Vue SPA Start
         */
        const app = new Vue({
            el: '#app',
            store,
            /* eslint no-unused-vars: 0 */
            render: h => (<router-view/>),
            router
        });
    });
