<template>
    <li>
        <div class="tree-group default-not-click" :style="indent"
             :class="{'treeactive':groupData.id === activeId,
                      'notClick': !levelStage && groupData.default === 1,
                      'noSelectable': groupData.noSelect === false && groupData.default === 2 }"
             @click="singleMenu(groupData,action,moduleRange)">
            <div class="tree-start-controller" v-if="hasChild">
                <button type="button" class="toggle-action-btn" @click.stop="toggle(groupData)">
                    <svg aria-hidden="true" v-show="!groupData.open">
                        <use xlink:href="#icon-right"></use>
                    </svg>
                    <svg aria-hidden="true" v-show="groupData.open">
                        <use xlink:href="#icon-arrow-down"></use>
                    </svg>
                </button>
            </div>
            <span class="group-name" :title="groupData.name">{{groupData.name}}</span>
            <div class="operation hidden-menu-trigger" @click.stop v-if="showMenu">
                <svg aria-hidden="true" class="gengduo">
                    <use xlink:href="#icon-gengduo"></use>
                </svg>
                <ul class="hidden-menu" @click="singleMenu(groupData,action,moduleRange)">
                    <li v-if="groupData.level < displayLevel + 1 && !$has(addGroup)" @click="actionGroup('create', groupData, action)">
                        {{'common.new' | t}}
                    </li>
                    <li v-if="!$has(modifyGroup)" @click="actionGroup('edit', groupData, action)">
                        {{'common.edit' | t}}
                    </li>
                    <li v-if="!$has(delGroup)" @click="actionGroup('del',groupData, action)">{{'common.del' | t}}</li>
                </ul>
            </div>
        </div>
        <ul v-if="hasChild" v-show="groupData.open">
            <tree-menu v-for="(item,index) in groupData.childs" :groupData="item"
                       :key="index" :depth="depth + 1" :action="action" :levelStage="levelStage"
                       :activeId="activeId" @choose="choose" :moduleRange="moduleRange"></tree-menu>
        </ul>
    </li>
</template>
<script>
    import Bus from './bus.js';

    export default {
        name: 'tree-menu',
        data() {
            return {
                // open:false,
                fathersArr: [],
                currentId: '',
            };
        },
        props: {
            groupData: Object,
            depth: Number,
            action: Boolean,//1、Boolean 是否显示操作图标 ... 2、点击单个tree-menu是否刷新右侧列表
            levelStage: Boolean,//是否显示末级
            activeId: [String, Number],
            moduleRange: String,//模块
            displayLevel:{//显示层级 最多为几级
                type:Number,
                default:4
            }
        },
        computed: {
            hasChild() {
                let hideTrue = !this.levelStage && this.groupData.level === this.displayLevel;
                return this.groupData.childs && this.groupData.childs.length && !hideTrue;
            },
            indent() {
                if (this.hasChild) {
                    return {'padding-left': this.depth * 16 + 'px'};
                }
                if (!this.hasChild) {
                    return {'padding-left': this.depth * 16 + 32 + 'px'};
                }
            },
            // 权限-新建分组
            addGroup() {
                switch (this.moduleRange) {
                    //素材、邮件头尾模板共用素材的权限
                    case 'materialModule':
                    case 'headerfooterEmail':
                        return 'journey_material_add_group';
                    //旅程
                    case 'journey':
                        return 'journey_manage_add_group';
                    //标签
                    case 'tagManageModule':
                        return 'datacentre_label_add_group';
                    // 表单
                    case 'questionnaire':
                        return 'journey_form_add_group';
                    // 二维码
                    case 'qr-code':
                        return 'wechat_qrcode_add_group';
                    // 用户分群
                    case 'swarm':
                        return 'customer_swarm_add_group';
                }
            },
            // 权限-编辑分组
            modifyGroup() {
                switch (this.moduleRange) {
                    //素材、邮件头尾模板共用素材的权限
                    case 'materialModule':
                    case 'headerfooterEmail':
                        return 'journey_material_edit_group';
                    //旅程
                    case 'journey':
                        return 'journey_manage_edit_group';
                    //标签
                    case 'tagManageModule':
                        return 'datacentre_label_edit_group';
                    // 表单
                    case 'questionnaire':
                        return 'journey_form_edit_group';
                    // 二维码
                    case 'qr-code':
                        return 'wechat_qrcode_edit_group';
                        // 用户分群
                    case 'swarm':
                        return 'customer_swarm_edit_group';
                }
            },
            // 权限-删除分组
            delGroup() {
                switch (this.moduleRange) {
                    //素材、邮件头尾模板共用素材的权限
                    case 'materialModule':
                    case 'headerfooterEmail':
                        return 'journey_material_delete_group';
                    //旅程
                    case 'journey':
                        return 'journey_manage_delete_group';
                    //标签
                    case 'tagManageModule':
                        return 'datacentre_label_delete_group';
                    // 表单
                    case 'questionnaire':
                        return 'journey_form_delete_group';
                    // 二维码
                    case 'qr-code':
                        return 'wechat_qrcode_delete_group';
                    // 用户分群
                    case 'swarm':
                        return 'customer_swarm_delete_group';
                }
            },
            /**
             * 显示分组操作菜单
             * 1. 非默认分组
             * 2. 是左侧列表
             * 3. 有新建分组，编辑分组或删除分组的操作权限
             */
            showMenu() {
                return this.groupData.default === 0
                    && this.action
                    && (!this.$has(this.addGroup) || !this.$has(this.modifyGroup) || !this.$has(this.delGroup));
            },
        },
        mounted() {
            // 选中的分组 所有父级的open=true 展开
            Bus.$on('open', allTreeGroup => {
                this.currentId = allTreeGroup.groupData.id;
                this.getParents(allTreeGroup.treeData);
                this.fathersArr.forEach(group => {
                    group.open = true;
                });
            });
        },
        methods: {
            toggle(groupData) {
                groupData.open = !groupData.open;
            },
            choose(groupData) {
                this.$emit('choose', groupData);
            },
            // tree菜单点击
            singleMenu(groupData, action, moduleRange) {
                if (groupData.default === 2 && groupData.noSelect === false) return;
                Bus.$emit('singleMenu', {groupData, action, moduleRange});
                this.choose(this.groupData);
            },
            // 操作分组（新建，编辑，删除）
            actionGroup(type, groupData, action) {
                Bus.$emit('actionGroup', {type, groupData, action});
            },
            // 根据选中的分组，找到该分组的所有父级
            getParents(data) {
                const _this = this;
                for (let i = 0; i < data.length; i++) {
                    let temp = data[i];
                    if (temp.id == this.currentId) {
                        this.fathersArr.push(temp);
                        return 1;
                    }
                    if (temp && temp.childs && temp.childs.length > 0) {
                        let t = _this.getParents(temp.childs);
                        if (t == 1) {
                            this.fathersArr.push(temp);
                            return 1;
                        }
                    }
                }
            },
        }
    };
</script>

<style lang="scss">
    @import '../../scss/base/mixin';
    @import '../../scss/base/variables';

    .tree-group {
        display: flex;
        line-height: 48px;
        cursor: pointer;
        position: relative;

        &:hover {
            background-color: $select-bg;
            color: $green;
            border-color: $green;
        }

        .tree-start-controller {
            .toggle-action-btn {
                width: 16px;
                height: 16px;
                margin: 16px 5px 0 10px;
                vertical-align: top;
                border: none;
                padding: 0;
                border-radius: 100%;
                background-color: $form-border;
                @include transition();

                &:not([disabled]) {
                    cursor: pointer;

                    &:hover {
                        background-color: $blue;
                    }
                }

                svg {
                    display: block;
                    width: 8px;
                    height: 8px;
                    margin: 4px;
                    fill: white;
                    cursor: inherit;
                    @include transition();
                }
            }
        }

        .group-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 245px;
            padding-right: 24px;
        }

        .operation {
            position: absolute;
            right: 5px;
            top: 3px;

            .gengduo {
                width: 16px;
                height: 16px;
                fill: $color-light-content;
            }

            .hidden-menu {
                top: 24px;
                right: 0px;
                @include transform-origin(left top);
            }
        }
       
    }
    .notClick{
        display: none;
    }
    .treeactive {
        background-color: $select-bg;
        color: $green;
    }
    /*全部分组 不可选 样式*/
    .noSelectable{
        cursor: not-allowed;
        background-color: #ffffff;
        color: $border-color;
        &:hover {
             background-color: #ffffff;
             color: $border-color;
         }
    }
</style>