var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "a",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible",
          },
        ],
        staticClass: "back-to-top",
        class: _vm.relative ? "relative" : "fixed",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.backTop.apply(null, arguments)
          },
        },
      },
      [
        _c("svg", { attrs: { "aria-hidden": "true" } }, [
          _c("use", { attrs: { "xlink:href": "#icon-mp-top" } }),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }