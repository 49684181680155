import Model from 'web-model';
import LINKS from '../LINKS';
export default new Model({
    base: LINKS.GATE_ORIGIN + '/api/',
    api: {
        /**
         * 查看发送通道列表
         * @param departmentId
         * @param searchType
         */
        getAllSendChannel({departmentId, searchType}) {
            return this.request
                .get('getAllSendChannel')
                .query({departmentId, searchType});
        },
    }
});