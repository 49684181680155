var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fall-and-fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.open,
            expression: "open",
          },
        ],
        staticClass: "toast",
        class: _vm.type,
      },
      [
        _vm.type
          ? _c("svg", { attrs: { "aria-hidden": "true" } }, [
              _c("use", { attrs: { xlink: "", href: "#icon-" + _vm.type } }),
            ])
          : _vm._e(),
        _vm._v("\n        " + _vm._s(_vm.message) + "\n    "),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }