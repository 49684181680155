var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        title: _vm.title ? _vm.title : _vm.$t("common.defaultConfirmTitle"),
        size: _vm.size,
      },
      on: { close: _vm.cancel },
      scopedSlots: _vm._u([
        {
          key: "buttons",
          fn: function () {
            return [
              _c("input", {
                staticClass: "btn btn-md btn-theme-border",
                attrs: { type: "button", value: _vm.$t("common.cancel") },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.cancel.apply(null, arguments)
                  },
                },
              }),
              _vm._v(" "),
              _c("input", {
                staticClass: "btn btn-md btn-theme",
                attrs: {
                  type: "button",
                  tabindex: "1",
                  autofocus: "",
                  value: _vm.$t("common.confirm"),
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.sure.apply(null, arguments)
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }