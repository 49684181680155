var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "block-empty" }, [
    _vm.search
      ? _c("img", { attrs: { src: "no-result.png", alt: "" } })
      : _c("img", { attrs: { src: require("./no-data.png"), alt: "" } }),
    _vm._v(" "),
    _c("p", { staticClass: "no-data-msg" }, [_vm._v(_vm._s(_vm.text))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }