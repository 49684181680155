var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "blur",
          rawName: "v-blur",
          value: _vm.hideList,
          expression: "hideList",
        },
      ],
      staticClass: "selector",
    },
    [
      _c(
        "label",
        {
          staticClass: "select-text",
          class: [
            {
              disabled: _vm.disabled,
              active: _vm.show,
              placeholder: !_vm.selectTitle && !_vm.icon,
            },
            _vm.icon ? "icon icon-" + _vm.icon : "",
          ],
          on: {
            click: function ($event) {
              !_vm.disabled && _vm.toggle()
            },
          },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.icon
                  ? ""
                  : _vm.selectTitle ||
                      _vm.placeholder ||
                      _vm.$t("common.selectHolder")
              ) +
              "\n        "
          ),
          _c("span", { staticClass: "select-icon icon icon-arrow-down" }),
        ]
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "drop" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show",
              },
            ],
            staticClass: "list-container",
            class: [_vm.direction, { "auto-width": _vm.autoWidth }],
          },
          [
            _vm.search
              ? _c("label", { staticClass: "search-field" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.keyword,
                        expression: "keyword",
                      },
                    ],
                    ref: "search",
                    attrs: {
                      type: "text",
                      placeholder: _vm.$t("common.selectKeywordHolder"),
                      autofocus: "",
                    },
                    domProps: { value: _vm.keyword },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.keyword = $event.target.value
                        },
                        _vm.searchOptions,
                      ],
                    },
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "icon icon-search",
                    on: { click: _vm.searchOptions },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.filters
              ? _c(
                  "div",
                  { staticClass: "filter-selector" },
                  [
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "blur",
                            rawName: "v-blur",
                            value: _vm.closeFilters,
                            expression: "closeFilters",
                          },
                        ],
                        staticClass: "filter-text icon icon-tag",
                        on: { click: _vm.toggleFilters },
                      },
                      [
                        _c("span", { staticClass: "filter-placeholder" }, [
                          _vm._v(
                            _vm._s(
                              _vm.filter.name ||
                                _vm.filterPlaceholder ||
                                _vm.$t("common.selectHolder")
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", {
                          staticClass: "icon icon-arrow-down",
                          class: { rotate: _vm.showFilter },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c("transition", { attrs: { name: "drop" } }, [
                      _vm.showFilter
                        ? _c(
                            "ul",
                            { staticClass: "filter-list" },
                            [
                              _vm._l(_vm.filters, function (f) {
                                return _c(
                                  "li",
                                  {
                                    class: { selected: _vm.filter.id === f.id },
                                    attrs: { title: f.name },
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeFilter(f)
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      style: "background-color:" + f.color,
                                    }),
                                    _vm._v(
                                      _vm._s(f.name) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              }),
                              _vm._v(" "),
                              _vm.filters.length === 0
                                ? _c("li", [
                                    _vm._v(
                                      _vm._s(_vm._f("t")("components.noData"))
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "load",
                    rawName: "v-load",
                    value: _vm.loadMore,
                    expression: "loadMore",
                  },
                ],
                staticClass: "select-list",
                class: { checkList: _vm.type === "checkSelect" },
              },
              [
                _c(
                  "draggable",
                  {
                    attrs: {
                      group: { name: "opt" },
                      animation: 150,
                      disabled: !_vm.draggable,
                    },
                    on: { change: _vm.orderOptions },
                    model: {
                      value: _vm.filteredOptions,
                      callback: function ($$v) {
                        _vm.filteredOptions = $$v
                      },
                      expression: "filteredOptions",
                    },
                  },
                  [
                    _vm._l(_vm.filteredOptions, function (opt) {
                      return _c(
                        "li",
                        {
                          staticClass: "option",
                          attrs: { title: opt.title || opt.name },
                        },
                        [
                          _vm.type === "checkSelect"
                            ? _c(
                                "checkbox",
                                {
                                  attrs: {
                                    source: opt[_vm.uniqueKey],
                                    disabled: opt.disabled,
                                  },
                                  model: {
                                    value: _vm.checkedArray,
                                    callback: function ($$v) {
                                      _vm.checkedArray = $$v
                                    },
                                    expression: "checkedArray",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        opt.name || _vm.$t("common.unknown")
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              )
                            : _vm.type === "groupSelect" && opt.title
                            ? _c("p", {
                                staticClass: "group-title",
                                domProps: { textContent: _vm._s(opt.title) },
                              })
                            : _c(
                                "p",
                                {
                                  staticClass: "option-text",
                                  class: {
                                    selected:
                                      opt[_vm.uniqueKey] ===
                                      _vm.selectedOpt[_vm.uniqueKey],
                                    "option-text": _vm.type === "checkSelect",
                                    disabled: opt.disabled,
                                  },
                                  on: {
                                    click: function ($event) {
                                      !opt.disabled && _vm.change(opt)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(
                                        opt.name || _vm.$t("common.unknown")
                                      ) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                          _vm._v(" "),
                          _vm.draggable
                            ? _c("span", {
                                staticClass: "order icon icon-tuozhuai1",
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _vm.filteredOptions.length === 0
                      ? _c(
                          "li",
                          { staticClass: "option-null" },
                          [
                            _vm.keyword
                              ? [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm._f("t")("components.without"))
                                  ),
                                  _c("span", [_vm._v(_vm._s(_vm.keyword))]),
                                  _vm._v(
                                    _vm._s(_vm._f("t")("components.relevant")) +
                                      "\n                        "
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    _vm._s(_vm._f("t")("components.noOption"))
                                  ),
                                ],
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.type === "checkSelect" && _vm.shortcutAction
              ? _c(
                  "div",
                  { staticClass: "all-checked" },
                  [
                    _c(
                      "checkbox",
                      {
                        attrs: { source: false },
                        model: {
                          value: _vm.allChecked,
                          callback: function ($$v) {
                            _vm.allChecked = $$v
                          },
                          expression: "allChecked",
                        },
                      },
                      [_vm._v(_vm._s(_vm._f("t")("common.allSelected")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "inverse", on: { click: _vm.inverse } },
                      [_vm._v(_vm._s(_vm._f("t")("common.invert")))]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }