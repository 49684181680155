var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "backdrop-fade" } }, [
    _c(
      "div",
      {
        staticClass: "backdrop",
        class: { scroll: _vm.scroll },
        on: {
          scroll: function ($event) {
            $event.stopPropagation()
            if ($event.target !== $event.currentTarget) return null
            $event.preventDefault()
          },
        },
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }