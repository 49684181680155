var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    attrs: { type: "text" },
    domProps: { value: _vm.value },
    on: {
      input: _vm.inputChecker,
      change: _vm.changeChecker,
      keyup: function ($event) {
        return _vm.$emit("keyup", $event)
      },
      keydown: _vm.keydownChecker,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }