var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wechat-preview" }, [
    _vm.type == 1 && _vm.pageType === "summary"
      ? _c("div", { staticClass: "summary-preview-wrapper" }, [
          _c(
            "div",
            {
              staticClass: "main-material",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.selectMaterial(_vm.previewItem)
                },
              },
            },
            [
              _c("div", { staticClass: "img-wrapper" }, [
                _c("img", {
                  staticClass: "main-thumb",
                  attrs: {
                    src:
                      _vm.previewItem.thumbMediaUrl || _vm.previewItem.thumbUrl,
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "main-title" }, [
                  _c("p", [_vm._v(_vm._s(_vm.previewItem.title))]),
                ]),
              ]),
              _vm._v(" "),
              _vm.previewItem.digest !== ""
                ? _c("p", { staticClass: "abstract" }, [
                    _vm._v(
                      _vm._s(_vm._f("t")("material.abstract")) +
                        " " +
                        _vm._s(_vm.previewItem.digest)
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "sub-material-wrapper" },
            _vm._l(_vm.previewItem.materialItems, function (item) {
              return _c(
                "li",
                {
                  staticClass: "sub-material",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.selectMaterial(item)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "content" }, [
                    _c("p", { staticClass: "sub-title" }, [
                      _vm._v(_vm._s(_vm.textSplice(item.title, 86, true))),
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "sub-trumb",
                      attrs: { src: item.thumbMediaUrl },
                    }),
                  ]),
                  _vm._v(" "),
                  _vm._m(0, true),
                ]
              )
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.type == 1 && _vm.pageType === "detail"
      ? _c("div", { staticClass: "detail-preview-wrapper" }, [
          _c(
            "p",
            {
              staticClass: "back",
              on: {
                click: function ($event) {
                  return _vm.back()
                },
              },
            },
            [_vm._v("< " + _vm._s(_vm._f("t")("material.backToCover")))]
          ),
          _vm._v(" "),
          _c("iframe", {
            ref: "contentView",
            staticClass: "content-frame",
            attrs: { frameborder: "0", scrolling: "yes" },
            on: {
              load: function ($event) {
                return _vm.insertContent(_vm.selectedMaterial.content)
              },
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.type == 2
      ? _c("div", { staticClass: "img-preview" }, [
          _c("img", { attrs: { src: _vm.previewItem.imageUrl, alt: "" } }),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "shadow" }, [
      _c("div", { staticClass: "circel" }, [
        _c("i", { staticClass: "icon icon-search" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }