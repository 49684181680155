const App = () => import(/* webpackChunkName: "app", webpackPrefetch: true */ '../layout/index.vue');
const customerManage = () => import(/* webpackChunkName: "customer", webpackPrefetch: true */'../customer/list/customer-manage.vue');
const customerDetail = () => import(/* webpackChunkName: "customer", webpackPrefetch: true */'../customer/list/customer-detail.vue');
const Swam = () => import(/* webpackChunkName: "customer", webpackPrefetch: true */'../customer/swarm/swarm.vue');
const SwarmUsers = () => import(/* webpackChunkName: "customer", webpackPrefetch: true */'../customer/swarm/swarmUsers.vue');
const customerUpload = () => import(/* webpackChunkName: "customer", webpackPrefetch: true */'../customer/swarm/customer-upload.vue');

/**
 * 客户管理
 */
export default {
    path: '/customer',
    name: 'customer',
    meta: {name: 'Dmartech', auth: true},
    component: App,
    redirect: {name: 'home'},
    children: [
        {path: 'manage', name: 'memberList', meta: {name: 'home.customer'}, component: customerManage},
        {path: 'manage/upload', name: 'customerUpload', meta: {auth: 'memberList', name: 'swarm.upload'}, component: customerUpload},
        {
            path: 'manage/detail',
            name: 'customerDetail',
            meta: {auth: 'memberList', name: 'contact.contactDetail'},
            component: customerDetail
        },
        {path: 'swarm', name: 'swarm', meta: {name: 'home.customerSwarm'}, component: Swam},
        {path: 'swarm/:id', name: 'swarmUsers', meta: {auth: 'swarm', name: 'swarm.contactsOfGroup'}, component: SwarmUsers}
    ]
};
