/**
 * Created by lvjuan on 2019/8/22.
 */
const accountInfoModify = () => import(/* webpackChunkName: "org-setting", webpackPrefetch: true */'../alter/accountInfoModify.vue');
/**
 * 账户信息修改
 */
export default {
    path: '/accountInfoModify',
    name: 'accountInfoModify',
    meta: {name: 'home.accountInfoModify'},
    component: accountInfoModify,
};

