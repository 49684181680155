import Model from 'web-model';
import LINKS from '../LINKS';
import {store as i18nStore} from '../common/i18n/i18n';

export default new Model({
    api: {

        /**
         * 登录
         * @param email
         * @param password
         */
        signIn(email, password) {
            return this.request
                .post(LINKS.MANAGE_API_BASE + '/api/manage/v1/account/login')
                .escape('before')
                .set('Accept-Language', i18nStore.state.lang)
                .send({
                    email,
                    password,
                    isScrm: 1
                });
        },


        /**
         * 用户信息
         * @param email
         */
        userProfile(email) {
            return this.request
                .get(LINKS.MANAGE_API_BASE + '/api/manage/v1/account/home/profile')
                .query({email});
        },

        /**
         * 华为登录获取用户信息
         * @param userNumber
         */
        hwUserProfile(userNumber) {
            return this.request
                .get(LINKS.MANAGE_API_BASE + '/api/manage/v1/account/getToken')
                .escape('before')
                .query({userNumber});
        },


        /**
         * 修改密码
         * @param email
         * @param newPassword
         * @param oldPassword
         */
        changePassword(email, newPassword, oldPassword) {
            return this.request
                .post(LINKS.GATE_ORIGIN + '/api/account/changePwd')
                .send({
                    email,
                    newPassword,
                    oldPassword
                });
        },


        /**
         * 获取没有使用权限菜单的预览内容
         * @param menuId
         * @return {*}
         */
        getUnAuthorizedPreview(menuId) {
            return this.request
                .post(LINKS.GATE_ORIGIN + '/api/permitAll/getPreview/' + menuId);
        },


        /**
         * 获取 SCRM 中绑定的微信公众号列表
         * @return {*}
         */
        getWechatAccounts() {
            return this.request
                .post(`${LINKS.WECHAT_API_BASE}/api/wechat/v1/authorization/bindingList`);

        },

        /**
         * 获取旅程审批（是否有权限）
         * @param userId    用户id
         * @returns {*}
         */
        getConfig(userId) {
            return this.request
                .get(LINKS.GATE_ORIGIN + '/api/globalConfig')
                .query({userId});
        },
        /**
         * 获取两步验证（是否开启）
         * @param userId    用户id
         * @returns {*}
         */
        getSystemConfig(userId) {
            return this.request
                .get(LINKS.GATE_ORIGIN + '/api/getSystemConfig')
                .query({userId});
        },
        /**
         * 发送验证码
         * @param email     邮箱
         * @param password  密码
         * @param phone     手机号
         * @param areaCode  区号
         * @returns {*}
         */
        sendSMSCode(email, password, phone, areaCode) {
            return this.request
                .get(LINKS.GATE_ORIGIN + '/api/validatePwd')
                .query({email, password, phone, areaCode});
        },
        /**
         * 校验验证码
         * @param email         邮箱
         * @param validatePwd   验证码
         * @param phone         手机号
         * @returns {*}
         */
        verifySMSCode(email, validatePwd, phone) {
            return this.request
                .get(LINKS.GATE_ORIGIN + '/api/verify')
                .query({email, validatePwd, phone});
        },
        /**
         * 编辑两步验证
         * @param userId    用户 ID
         * @returns {*}
         */
        changeTwoFactor(userId) {
            return this.request
                .put(LINKS.GATE_ORIGIN + '/api/editSystemConfig')
                .query({userId});
        },
    }
});

export const importantModel = new Model({
    base: 'https://gate.dmartech.cn/etl/api/v1',
    api: {
        eventTag(data) {
            return this.request
                .post('/api/import?secret=dad4727a-57fb-451f-8df0-084f2ea7112e')
                .send(data);
        }
        
    }
});
