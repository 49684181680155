var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "timer" }, [
    _c(
      "button",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show,
            expression: "show",
          },
        ],
        staticClass: "btn btn-md btn-theme",
        attrs: { type: "button", disabled: _vm.disabled },
        on: { click: _vm.getCode },
      },
      [
        _vm._v(
          "\n        " +
            _vm._s(_vm._f("t")("common.sendValidateCode")) +
            "\n    "
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.show,
            expression: "!show",
          },
        ],
        staticClass: "btn btn-md btn-theme",
        attrs: { type: "button" },
      },
      [_vm._v(_vm._s(_vm.count) + " S")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }