<template>
    <modal v-if="visible" size="lg" neat :title="title" @close="close">
        <preview :material="material" :mmsMaterial="mmsMaterial" :signature="signature" :toolbar="toolbar"
                 :class="{'preview-html-in-modal': previewingHtml}"/>
    </modal>
</template>
<script type="text/ecmascript-6">

    import Preview from './preview.vue';

    export default {
        data() {
            return {
                material: {},
                mmsMaterial: [],
                visible: false,
                toolbar: true,
                signature: '',
            };
        },
        computed: {
            title() {
                let title = this.material.materialName || this.material.title;
                if (this.material.subject) {
                    let subject = this.material.subject;
                    return title ? this.$t('controller.preview_material') + ' - ' + title +  ' - ' + subject : this.$t('controller.preview_material');
                } else {
                    return title ? this.$t('controller.preview_material') + ' - ' + title : this.$t('controller.preview_material');
                }
            },
            previewingHtml() {
                return this.material.type === 1 || this.material.type === 3;
            }
        },
        methods: {
            close() {
                this.visible = false;
                this.$set(this.$data, 'material', {});
            }
        },
        components: {
            Preview
        }
    };
</script>
<style lang="scss">
    .preview-html-in-modal {
        height: 70vh;
    }
</style>
