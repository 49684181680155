<template>
    <div class="wechat-preview">
        <div v-if="type == 1 && pageType === 'summary'" class="summary-preview-wrapper">
            <div class="main-material" @click.stop="selectMaterial(previewItem)">
                <div class="img-wrapper">
                    <img :src="previewItem.thumbMediaUrl|| previewItem.thumbUrl" class="main-thumb">
                    <div class="main-title">
                        <p>{{previewItem.title}}</p>
                    </div>
                </div>
                <p v-if="previewItem.digest!==''" class="abstract">{{'material.abstract' | t}} {{previewItem.digest}}</p>
            </div>
            <ul class="sub-material-wrapper">
                <li v-for="item in previewItem.materialItems" class="sub-material" @click.stop="selectMaterial(item)">
                    <div class="content">
                        <p class="sub-title">{{textSplice(item.title, 86, true)}}</p>
                        <img :src="item.thumbMediaUrl" class="sub-trumb">
                    </div>
                    <div class="shadow">
                        <div class="circel"><i class="icon icon-search"></i></div>
                    </div>
                </li>
            </ul>
        </div>
        <div v-if="type == 1 && pageType === 'detail'" class="detail-preview-wrapper">
            <p class="back" @click="back()">&lt;&nbsp;{{'material.backToCover' | t}}</p>
            <iframe class="content-frame" ref="contentView" frameborder="0" scrolling="yes"
                    @load="insertContent(selectedMaterial.content)"></iframe>
            <!--<div class="content-title" ref="contentTitle">-->
            <!--<h1 class="title" style="font-weight: normal">{{selectedMaterial.title}}</h1>-->
            <!--<p class="dataAuthor" style="color: #666666">{{selectedMaterial.updateDate}}&nbsp;<span-->
            <!--style="color: #999999">{{selectedMaterial.author}}</span></p>-->
            <!--</div>-->
        </div>
        <div v-if="type == 2" class="img-preview">
            <img :src="previewItem.imageUrl" alt="">
        </div>
    </div>
</template>
<script type="text/ecmascript-6">
    import {sliceWords} from '../../common/utils/mixin';

    export default {
        props: {
            previewItem: Object
        },
        data() {
            return {
                // 2 kinds of page type: summary(default) and detail
                pageType: 'summary',
                selectedMaterial: {},
                imageSrc: ''
            };
        },
        computed: {
            type() {
                // 1 图文 2 图片
                return this.previewItem.imageUrl ? 2 : 1;
            }
        },
        methods: {
            textSplice(content, slice, ellipsis) {
                return sliceWords(content, slice, ellipsis);
            },
            selectMaterial(material) {
                this.selectedMaterial = material;
                this.pageType = 'detail';
            },
            back() {
                this.pageType = 'summary';
                this.selectedMaterial = {};
            },
            insertContent() {
                let content = `
                    <h1 class="title" style="font-weight: normal">${this.selectedMaterial.title}</h1>
                    <p class="dataAuthor" style="color: #666666">
                        ${this.selectedMaterial.updateDateString || this.selectedMaterial.updateDate || this.previewItem.updateDateString || this.previewItem.updateDate}&nbsp;
                        <span style="color: #999999">${this.selectedMaterial.author}</span>
                    </p>
                    <div style="color: #999999; word-wrap: break-word;">${this.selectedMaterial.content}</div>
                `;
                if (this.selectedMaterial.contentSourceUrl) {
                    content += `<a style="text-decoration: none;" href=${this.selectedMaterial.contentSourceUrl} target="_blank">原文链接</a>`;
                }
                this.$refs.contentView.contentDocument.body.innerHTML = content;
                this.$refs.contentView.style.height = '430px';
            }
        }
    };
</script>
<style lang="scss">
    @import "../../common/scss/base/variables";
    @import "../../common/scss/base/mixin";

    .wechat-preview {
        padding: 20px;

        .summary-preview-wrapper {
            overflow-y: auto;
            overflow-x: hidden;
            margin: 0 auto;
            max-width: 490px;
            border: 1px solid $border-color;
            border-radius: 10px;
            cursor: pointer;

            .main-material {
                padding: 20px 20px 10px 20px;
                border-bottom: 1px solid $border-color;

                .img-wrapper {
                    position: relative;
                    overflow: hidden;
                    height: 255px;

                    .main-thumb {
                        display: block;
                        width: 100%;
                        height: auto;
                    }

                    .main-title {
                        position: absolute;
                        @include box-sizing();
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 85px;
                        padding: 18px;
                        font-size: 18px;
                        background: rgba(0, 0, 0, .8);
                        @include ellipsis();

                        p {
                            overflow: hidden;
                            white-space: normal;
                            height: 50px;
                            line-height: 1.5em;
                            color: white;
                        }
                    }
                }

                .abstract {
                    margin: 10px 0;
                    @include ellipsis;
                }
            }
            .sub-material-wrapper {

                .sub-material {
                    position: relative;
                    height: 85px;
                    .content {
                        box-sizing: border-box;
                        padding: 10px 16px;
                        height: 100%;

                        .sub-title {
                            float: left;
                            @include box-sizing();
                            height: 100%;
                            width: -webkit-calc(100% - #{29px + 65px});
                            width: calc(100% - #{29px + 65px});
                            padding: 8px 0;
                            margin-right: 29px;
                            font-size: 16px;
                            line-height: 1.5em;
                            @include ellipsis();
                            color: $color-content;
                        }

                        .sub-trumb {
                            float: left;
                            display: block;
                            width: 65px;
                            height: 65px;
                        }
                    }

                    .shadow {
                        display: none;
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        @include linear-gradient(rgba(0, 0, 0, .2), rgba(0, 0, 0, .8));

                        .circel {
                            margin: 24px auto;
                            width: 37px;
                            height: 37px;
                            border-radius: 50%;
                            background: white;

                            .icon-search {
                                display: block;
                                line-height: 37px;
                                text-align: center;
                                font-size: 18px;
                                color: $color-light-content;
                            }
                        }
                    }
                    &:hover {
                        .shadow {
                            display: block;
                        }
                    }
                }
            }
        }

        .detail-preview-wrapper {
            .back {
                line-height: $input-field-height;
                color: $color-light-content;
                cursor: pointer;
            }

            .content-title {
                margin: 0 auto;

                .title {
                    font-size: 24px;
                }

                .dataAuthor {
                    line-height: $input-field-height;
                    color: $color-light-content;
                }
            }
            .content-frame {
                width: 100%;
                margin: 0 auto;
                display: block;
            }
        }

        .img-preview {
            img {
                display: block;
                margin: auto;
                max-width: 100%;
                max-height: 100%;
                height: auto;
                width: auto;
            }
        }

    }
</style>
