var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "blur",
          rawName: "v-blur",
          value: _vm.close,
          expression: "close",
        },
      ],
      staticClass: "color-picker",
      style: { background: _vm.value },
      on: {
        click: function ($event) {
          if ($event.target !== $event.currentTarget) return null
          _vm.show = !_vm.show
        },
      },
    },
    [
      _vm.show
        ? _c("sketch-picker", {
            staticClass: "inner-picker",
            attrs: { disableAlpha: "", "preset-colors": _vm.presetColors },
            model: {
              value: _vm.colors,
              callback: function ($$v) {
                _vm.colors = $$v
              },
              expression: "colors",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }