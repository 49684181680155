/**
 * Created by lvjuan on 2019/8/22.
 */
// const App = () => import(/* webpackChunkName: "app", webpackPrefetch: true */ '../layout/index.vue');
const personal = () => import(/* webpackChunkName: "org-setting", webpackPrefetch: true */'../personal-setting/personal-setting.vue');
/**
 * 个人设置
 */
export default {
    path: '/personalSetting',
    name: 'personalSetting',
    meta: {name: 'personal.personalSettings'},
    component: personal,
};

