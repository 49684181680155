/**
 * Created by naeemo on 2016/12/1.
 */
import cloneDeep from 'lodash/cloneDeep';
import data from './data';
import wechat from './wechat';
import analysis from './analysis';
import customer from './customer';
import journey from './journey';
import setting from './setting';
import rootStore from '../common/vuex/rootStore';
import personal from './personal';
import alter from './alter';


const App = () => import(/* webpackChunkName: "app", webpackPrefetch: true */ '../layout/index.vue');
const Login = () => import(/* webpackChunkName: "app", webpackPrefetch: true */ '../layout/login/login.vue');
const Terms = () => import(/* webpackChunkName: "app", webpackPrefetch: true */ '../layout/terms.vue');
const Home = () => import(/* webpackChunkName: "app", webpackPrefetch: true */ '../layout/home/home.vue');
const NotFound = () => import(/* webpackChunkName: "app", webpackPrefetch: true */ '../layout/components/not-found.vue');
const NotAuthorized = () => import(/* webpackChunkName: "app", webpackPrefetch: true */ '../layout/components/not-authorized.vue');
const PluginWindow = () => import(/* webpackChunkName: "app", webpackPrefetch: true */ '../layout/components/plugin-window.vue');

/**
 * 登录前就会被注册的默认路由
 * @type {*[]}
 */
export const defaultRoutes = [
    // 登录
    {path: '/sign-in', name: 'login', meta: {name: 'home.login'}, component: Login},
    // 使用协议
    {path: '/terms', name: 'terms', meta: {name: 'home.terms'}, component: Terms},
    // 401 没有权限访问
    {path: '/401', name: 401, meta: {auth: true, name: 'home.noAuth'}, component: NotAuthorized},
    // 404 页面
    {path: '*', name: 404, meta: {auth: true, name: 'home.notFound'}, component: NotFound}
];

/**
 * 标准产品包含的所有路由
 * meta.auth 字段为字符串时，表征该路由的权限继承于 meta.auth 指定的路由
 * @type {*[]}
 */
const standardRoutes = [
    {
        path: '/',
        name: 'index',
        meta: {
            name: 'Dmartech',
            auth: true
        },
        component: App,
        redirect: {name: 'market'},
        children: [
            {name: 'home', path: 'home', meta: {auth: 'index', name: 'home.home'}, component: Home},
            setting, personal,alter
        ]
    },
    // 微信管理
    ...wechat,
    // 客户管理
    customer,
    // 营销旅程
    journey,
    // 数据中心
    data,
    // 分析模型
    analysis,
];


/**
 * 根据路由名(菜单名列表)称过滤路由
 * 这个方法返回的路由列表是所有【可见】的路由，
 * 具体每个路由是否【可用】，要取决于 $store.state.user.menus[routeName].type
 * type: 0代表可见不可用，1代表可点击（展示客服信息），2代表可用
 *
 * @param {Object<String, Object>} records 就是 $store.state.user.menus
 * @return {Array}
 */
export function getRoutesByMenuRecords(records) {

    // 记录没有注册成功的路由名称，在方法最后给出错误提醒
    let namesLeft = new Set(Object.keys(records));

    function filter(routes) {
        return routes.filter(route => {
            if (route.name in records || route.meta.auth in records) {
                const menu = records[route.name];
                const superiorMenu = records[route.meta.auth];
                // 注册上的路由从剩余名单里去除
                namesLeft.delete(route.name);

                let user = rootStore.state.user;
                // 主账号
                if (user.roleId === 0 || user.roleId === '') {
                    // 当前路由是否【可用】由 records[route.name].type 的值决定，一级菜单总是可用的
                    // route.name 不存在 records 中的，继承 records[route.meta.auth] 的相应值
                    // 没有可使用权限的路由展示 未授权 组件
                    if ((menu || superiorMenu).type !== 2 && (menu || superiorMenu).parentId !== 0) {
                        if (!route.children) {
                            route.component = NotAuthorized;
                        }
                    } else if (menu && menu.isPlugin) {
                        // 切换到插件视图
                        route.component = PluginWindow;
                        // 清空原有重定向
                        route.redirect = null;
                        // 添加插件功能所属公司前缀
                        let parentMenu = Object.values(records).find(record => record.id === menu.parentId);
                        if (parentMenu) route.path = '/' + parentMenu.showName + '/' + route.name;
                    }

                    // 循环处理子路由
                    if (route.children && route.children.length) {
                        route.children = filter(route.children);
                    }

                    return true;
                }
                // 子账号
                else {
                    // 当前路由是否【可用】由 records[route.name].type 的值决定，一级菜单总是可用的
                    // route.name 不存在 records 中的，继承 records[route.meta.auth] 的相应值
                    // 没有可使用权限的路由展示 未授权 组件
                    // 子账号菜单根据showType 判断； showType 0 没权限，1 有权限
                    if ((menu || superiorMenu).parentId !== 0) {
                        if ((menu || superiorMenu).showType === 0) {
                            route.component = NotAuthorized;
                        }
                    } else if (menu && menu.isPlugin) {
                        // 切换到插件视图
                        route.component = PluginWindow;
                        // 清空原有重定向
                        route.redirect = null;
                        // 添加插件功能所属公司前缀
                        let parentMenu = Object.values(records).find(record => record.id === menu.parentId);
                        if (parentMenu) route.path = '/' + parentMenu.showName + '/' + route.name;
                    }

                    // 循环处理子路由
                    if (route.children && route.children.length) {
                        route.children = filter(route.children);
                    }

                    return true;
                }
            } else {
                if (process.env.NODE_ENV !== 'production') {
                    console.warn(`路由 ${route.name} 未能注册成功，没有相应的菜单权限`);
                }
                return false;
            }
        });
    }

    // 筛选出有权限的标准路由
    // 深拷贝是必需的，这样重新登录之后，新的菜单权限才能生效
    const routes = filter(cloneDeep(standardRoutes));

    // 以【插件（iframe）】方式引入的功能模块，在这里注册路由
    namesLeft.forEach(name => {
        const menu = records[name];

        // 顶级路由
        if (menu.parentId === 0) {
            if (!routes.some(route => route.name === name)) { // 避免重复注册
                routes.push({
                    name,
                    path: '/' + name,
                    meta: {name: menu.translationKey, isPlugin: true},
                    component: App
                });
                namesLeft.delete(name);
            }
        } else {
            // 二级路由
            let parentMenu = Object.values(records)
                .find(record => record.id === menu.parentId);

            if (parentMenu) {
                let parentRoute = routes.find(route => route.name === parentMenu.showName);

                // 父级路由还没有，添加子路由前先添加父级路由
                if (!parentRoute) {
                    parentRoute = {
                        name: parentMenu.showName,
                        path: '/' + parentMenu.showName,
                        meta: {name: parentMenu.translationKey, isPlugin: true},
                        component: App
                    };
                    routes.push(parentRoute);
                    namesLeft.delete(parentMenu.showName);
                }

                // 确保父级路由有 children
                if (!parentRoute.children)
                    parentRoute.children = [];

                // 添加子路由
                parentRoute.children.push({
                    name,
                    path: name,
                    meta: {name: menu.translationKey, isPlugin: true},
                    component:  PluginWindow 
                });
                namesLeft.delete(name);
            }
        }
    });

    // 记录顶级插件路由
    const pluginRoutes = routes
        .filter(route => route.meta.isPlugin)
        .map(({name, path, meta, children = []}) => ({
            name,
            path,
            meta,
            children: children.map(({name, path, meta}) => ({name, path, meta}))
        }));


    if (namesLeft.size) {
        console.error('以下路由没有注册成功', Array.from(namesLeft));
    }

    return [routes, pluginRoutes];
}
