const App = () => import(/* webpackChunkName: "app", webpackPrefetch: true */ '../layout/index.vue');
const BindWechatOfficialAccount = () => import(/* webpackChunkName: "app", webpackPrefetch: true */ '../wechat/account/bind-wechat-official-account-now.vue');
const WechatAccountAuthorize = () => import(/* webpackChunkName: "app", webpackPrefetch: true */ '../wechat/account/wechat-account-authorize.vue');
const WechatAccountAuthorizeResult = () => import(/* webpackChunkName: "app", webpackPrefetch: true */ '../wechat/account/wechat-account-authorize-result.vue');
const AccountList = () => import(/* webpackChunkName: "app", webpackPrefetch: true */ '../wechat/account/account-list.vue');
// 群发功能
const massSend = () => import(/* webpackChunkName: "wechat-send", webpackPrefetch: true */'../wechat/mass-send/mass-send.vue');
const massSendForm = () => import(/* webpackChunkName: "wechat-send", webpackPrefetch: true */'../wechat/mass-send/mass-send-form.vue');
const massSentList = () => import(/* webpackChunkName: "wechat-send", webpackPrefetch: true */'../wechat/mass-send/mass-send-list.vue');

const groupMassage = () => import(/* webpackChunkName: "wechat-send", webpackPrefetch: true */'../wechat/mass-send/components/group-mass.vue');
const customerMassage = () => import(/* webpackChunkName: "wechat-send", webpackPrefetch: true */'../wechat/mass-send/components/customer-mass.vue');
const templateMassage = () => import(/* webpackChunkName: "wechat-send", webpackPrefetch: true */'../wechat/mass-send/components/tem-mass.vue');

// 自定义菜单
const menu = () => import(/* webpackChunkName: "wechat-menu", webpackPrefetch: true */'../wechat/menu/menu.vue');
const DIYMenu = () => import(/* webpackChunkName: "wechat-menu", webpackPrefetch: true */'../wechat/menu/diy-menu.vue');
// const customizeMenu = () => import(/* webpackChunkName: "wechat-menu", webpackPrefetch: true */'../wechat/menu/customize-menu.vue');
// const customizeMenuForm = () => import(/* webpackChunkName: "wechat-menu", webpackPrefetch: true */'../wechat/menu/customize-menu-form.vue');

// 自动回复
const reply = () => import(/* webpackChunkName: "wechat-reply", webpackPrefetch: true */'../wechat/reply/reply.vue');

// 消息管理
const WechatMessage = () => import(/* webpackChunkName: "wechat-message", webpackPrefetch: true */'../wechat/message/wechat-message.vue');
const UserChat = () => import(/* webpackChunkName: "wechat-message", webpackPrefetch: true */'../wechat/message/chat.vue');

// 粉丝管理
const FansManage = () => import(/* webpackChunkName: "wechat-fans", webpackPrefetch: true */'../wechat/fans/fans-manage.vue');
const WechatFansNow = () => import(/* webpackChunkName: "wechat-fans", webpackPrefetch: true */'../wechat/fans/fans.vue');
const wechatFansUsedToBe = () => import(/* webpackChunkName: "wechat-fans", webpackPrefetch: true */'../wechat/fans/un-followed.vue');

// 素材管理
const MaterialManage = () => import(/* webpackChunkName: "wechat-material", webpackPrefetch: true */'../wechat/material/material-manage.vue');
const imageTextList = () => import(/* webpackChunkName: "wechat-material", webpackPrefetch: true */'../wechat/material/image-text-material-list.vue');
const imageList = () => import(/* webpackChunkName: "wechat-material", webpackPrefetch: true */'../wechat/material/image-material-list.vue');
const audioList = () => import(/* webpackChunkName: "wechat-material", webpackPrefetch: true */'../wechat/material/audio-material-list.vue');
const videoList = () => import(/* webpackChunkName: "wechat-material", webpackPrefetch: true */'../wechat/material/video-material-list.vue');
const imageTextForm = () => import(/* webpackChunkName: "wechat-material", webpackPrefetch: true */'../wechat/material/image-text-material-form.vue');
const videoForm = () => import(/* webpackChunkName: "wechat-material", webpackPrefetch: true */'../wechat/material/video-material-form.vue');
const QRCodeList = () => import(/* webpackChunkName: "qr-code", webpackPrefetch: true */ '../wechat/qr-code/qr-code-list.vue');
const QRCodeForm = () => import(/* webpackChunkName: "qr-code", webpackPrefetch: true */ '../wechat/qr-code/qr-code-form.vue');
const QRCodeView = () => import(/* webpackChunkName: "qr-code", webpackPrefetch: true */ '../wechat/qr-code/qr-code-view.vue');
// 模板
const template = () => import(/* webpackChunkName: "wechat-template ", webpackPrefetch: true */'../wechat/template/template-manage.vue');
const myTemplate = () => import(/* webpackChunkName: "wechat-template", webpackPrefetch: true */'../wechat/template/template-list.vue');
const InvalidTemplate = () => import(/* webpackChunkName: "wechat-template", webpackPrefetch: true */'../wechat/template/invalid-template-list.vue');
const TemplateDetail = () => import(/* webpackChunkName: "wechat-template", webpackPrefetch: true */'../wechat/template/template-detail.vue');
const InvalidTemplateDetail = () => import(/* webpackChunkName: "wechat-template", webpackPrefetch: true */'../wechat/template/invalid-template-detail.vue');


/**
 * 微信管理
 */
export default [
    // 绑定公众号引导页
    {
        path: '/bind-wechat-official-account',
        name: 'bindWechatOfficialAccount',
        meta: {name: 'wechatAccount.DmarTechAuth', auth: 'wechat'},
        component: BindWechatOfficialAccount
    },
    // 授权公众号起始页
    {
        path: '/wechat-account-authorization',
        name: 'wechatAccountAuthorization',
        meta: {name: 'wechatAccount.authorizedToDmk', auth: 'wechat'},
        component: WechatAccountAuthorize
    },
    // 授权公众号落地页
    {
        path: '/wechat-account-authorization-result',
        name: 'wechatAccountAuthorizeResult',
        meta: {name: 'wechatAccount.authorizedToDmk', auth: 'wechat'},
        component: WechatAccountAuthorizeResult
    },
    {
        path: '/wechat',
        name: 'wechat',
        meta: {name: 'Dmartech', auth: true, officialAccountNecessary: true},
        component: App,
        redirect: {name: 'home'},
        children: [
            // 授权公众号列表
            {
                path: 'accounts',
                name: 'wechatAccounts',
                meta: {name: 'home.officialAccount', auth: 'wechat'},
                component: AccountList
            },
            // 二维码管理
            {
                path: 'qr-code',
                name: 'subscriptionManage',
                meta: {name: 'home.subscriptionManage'},
                component: QRCodeList,
            },
            {
                path: 'qr-code/:id/edit',
                name: 'qrCodeEdit',
                meta: {auth: 'subscriptionManage', name: 'QRCode.create'},
                component: QRCodeForm
            },
            {
                path: 'qr-code/:id/detail',
                name: 'qrCodeView',
                meta: {auth: 'subscriptionManage', name: 'QRCode.detail'},
                component: QRCodeView
            },
            // 发送消息
            {
                path: 'mass-send',
                name: 'massSend',
                redirect: 'mass-send/form',
                meta: {name: 'home.massSend', auth: true},
                component: massSend,
                children: [
                    {
                        path: 'form',
                        name: 'massSendForm',
                        meta: {name: 'home.createMessage', auth: 'massSend'},
                        component: massSendForm
                    },
                    {
                        path: 'list',
                        name: 'massSentList',
                        meta: {name: 'home.sent', auth: 'massSend'},
                        redirect: 'list/group',
                        component: massSentList,
                        children: [
                            {
                                path: 'group',
                                name: 'groupMessage',
                                meta: {name: 'home.group', auth: 'massSend'},
                                component: groupMassage,
                            },
                            {
                                path: 'customer',
                                name: 'customerMassage',
                                meta: {name: 'home.customService', auth: 'massSend'},
                                component: customerMassage,
                            },
                            {
                                path: 'tem',
                                name: 'templateMassage',
                                meta: {name: 'home.tem', auth: 'massSend'},
                                component: templateMassage,
                            },
                        ]
                    }
                ]
            },
            // 自动回复
            {path: 'reply', name: 'reply', meta: {name: 'home.reply'}, component: reply},
            // 自定义菜单, 个性化菜单
            {
                path: 'menus',
                name: 'menus',
                meta: {name: 'home.menu', auth: 'diyMenu'},
                redirect: 'menus/diy',
                component: menu,
                children: [
                    {path: 'diy', name: 'diyMenu', meta: {name: 'home.diyMenu'}, component: DIYMenu},
                    // {path: 'customize', name: 'customizeMenu', component: customizeMenu},
                    // {path: 'customize/:ruleId?', name: 'customizeMenuForm', component: customizeMenuForm}
                ]
            },
            // 消息管理
            {path: 'message', name: 'wechatMessageManage', meta: {name: 'home.message'}, component: WechatMessage},
            {
                path: 'message/chat',
                name: 'chat',
                meta: {auth: 'wechatMessageManage', name: 'message.chatLog'},
                component: UserChat
            },
            // 粉丝管理
            {
                path: 'fans',
                name: 'wechatFans',
                meta: {name: 'home.fans'},
                redirect: 'fans/following',
                component: FansManage,
                children: [
                    {
                        path: 'following',
                        name: 'wechatFansNow',
                        meta: {auth: 'wechatFans', name: 'follower.followers'},
                        component: WechatFansNow
                    },
                    {
                        path: 'un-followed',
                        name: 'wechatFansUsedToBe',
                        meta: {auth: 'wechatFans', name: 'follower.unFollow'},
                        component: wechatFansUsedToBe
                    }
                ]
            },
            // 微信素材
            {
                path: 'material',
                name: 'material',
                meta: {name: 'home.wechatMaterial'},
                redirect: 'material/image-text',
                component: MaterialManage,
                children: [
                    {
                        path: 'image-text',
                        name: 'imageTextList',
                        meta: {auth: 'material', name: 'wechatContent.appMessage'},
                        component: imageTextList
                    },
                    {
                        path: 'image-text/create',
                        name: 'imageTextCreate',
                        meta: {auth: 'material', name: 'QRCode.newAppMsg'},
                        component: imageTextForm
                    },
                    {
                        path: 'image-text/edit',
                        name: 'imageTextEdit',
                        meta: {auth: 'material', name: 'QRCode.editAppMsg'},
                        component: imageTextForm
                    },
                    {
                        path: 'img',
                        name: 'imageList',
                        meta: {auth: 'material', name: 'wechatContent.pics'},
                        component: imageList
                    },
                    {
                        path: 'audio',
                        name: 'audioList',
                        meta: {auth: 'material', name: 'wechatContent.audio'},
                        component: audioList
                    },
                    {
                        path: 'video',
                        name: 'videoList',
                        meta: {auth: 'material', name: 'wechatContent.video'},
                        component: videoList
                    },
                    {
                        path: 'video/form',
                        name: 'videoForm',
                        meta: {auth: 'material', name: 'QRCode.uploadVideo'},
                        component: videoForm
                    },
                ]
            },
            // 模板管理
            {
                path: 'template',
                name: 'template',
                meta: {name: 'home.template'},
                redirect: 'template/myTemplate',
                component: template,
                children: [
                    {
                        path: 'myTemplate',
                        name: 'myTemplate',
                        meta: {auth: 'template', name: 'template.myTemplate'},
                        component: myTemplate
                    },
                    {
                        path: 'invalid-template',
                        name: 'invalidTemplate',
                        meta: {auth: 'template', name: 'template.invalidTemplate'},
                        component: InvalidTemplate
                    },
                    {
                        path: 'myTemplate/compile',
                        name: 'templateDetail',
                        meta: {auth: 'template', name: 'template.editTemplate'},
                        component: TemplateDetail
                    },
                    {
                        path: 'invalid-template/detail',
                        name: 'invalidTemplateDetail',
                        meta: {auth: 'template', name: 'template.invalidDetails'},
                        component: InvalidTemplateDetail
                    }
                ]
            }
        ]
    }
];
