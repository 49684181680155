var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.editSenderShow
    ? _c(
        "modal",
        {
          staticClass: "sender-content",
          attrs: { title: _vm.$t("sendChannel.setSender") },
          on: { close: _vm.closeEditSender },
          scopedSlots: _vm._u(
            [
              {
                key: "buttons",
                fn: function () {
                  return [
                    _c("input", {
                      staticClass: "btn btn-md btn-theme",
                      attrs: {
                        type: "button",
                        value: _vm.$t("common.confirm"),
                      },
                      on: { click: _vm.closeEditSender },
                    }),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            2293079168
          ),
        },
        [
          _c("div", { staticClass: "form-field flex" }, [
            _c("label", { staticClass: "label", attrs: { for: "sendName" } }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "field-group", attrs: { id: "sendName" } },
              [
                _vm._l(_vm.senderList, function (sender, index) {
                  return [
                    sender.btnsShow
                      ? _c("div", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.trim",
                                value: sender.name,
                                expression: "sender.name",
                                modifiers: { trim: true },
                              },
                            ],
                            staticClass: "input lg",
                            attrs: { type: "text", maxlength: "100" },
                            domProps: { value: sender.name },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  sender,
                                  "name",
                                  $event.target.value.trim()
                                )
                              },
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-theme",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  if ($event.target !== $event.currentTarget)
                                    return null
                                  return _vm.editEmailSender(sender)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm._f("t")("common.save")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-sm btn-white",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  if ($event.target !== $event.currentTarget)
                                    return null
                                  return _vm.closeBtns(sender, index)
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm._f("t")("common.cancel")) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    sender.id && !sender.btnsShow
                      ? _c(
                          "div",
                          { staticClass: "sender-name" },
                          [
                            _c("span", [_vm._v(_vm._s(sender.name))]),
                            _vm._v(" "),
                            sender.senderType === 2
                              ? [
                                  _c("button", {
                                    directives: [
                                      {
                                        name: "title",
                                        rawName: "v-title:top",
                                        value: _vm.$t("common.edit"),
                                        expression: "$t('common.edit')",
                                        arg: "top",
                                      },
                                    ],
                                    staticClass: "action-icon icon icon-pencil",
                                    on: {
                                      click: function ($event) {
                                        return _vm.showBtns(sender)
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("button", {
                                    directives: [
                                      {
                                        name: "title",
                                        rawName: "v-title:top",
                                        value: _vm.$t("common.del"),
                                        expression: "$t('common.del')",
                                        arg: "top",
                                      },
                                    ],
                                    staticClass:
                                      "action-icon icon icon-iconless",
                                    on: {
                                      click: function ($event) {
                                        if (
                                          $event.target !== $event.currentTarget
                                        )
                                          return null
                                        return _vm.delSenders(sender, index)
                                      },
                                    },
                                  }),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "add-condition-btn",
              attrs: { type: "button" },
              on: { click: _vm.addSenders },
            },
            [
              _c("svg", [_c("use", { attrs: { "xlink:href": "#icon-plus" } })]),
              _vm._v(
                "\n        " +
                  _vm._s(_vm._f("t")("sendChannel.addEmailSender")) +
                  "\n    "
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }