var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "modal",
        {
          attrs: { size: "lg", neat: "", title: _vm.title },
          on: { close: _vm.close },
        },
        [
          _c("preview", {
            class: { "preview-html-in-modal": _vm.previewingHtml },
            attrs: {
              material: _vm.material,
              mmsMaterial: _vm.mmsMaterial,
              signature: _vm.signature,
              toolbar: _vm.toolbar,
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }