/**
 * Created by naeemo on 2017/3/7.
 */

import Model from 'web-model';
import LINKS from '../../LINKS';

export default new Model({
    base: LINKS.JOURNEY_MATERIAL_API_BASE + '/api/material/v1',
    api: {

        /**
         * 获取素材列表
         * @param groupId
         * @param name
         * @param pageNumber
         * @param type 0 for sms, 1 for email, empty for all
         * @param sort 1 更新时间升序；2 名称降序；3 名称升序；null或者其他更新时间降序
         */
        getMaterials(groupId, name, pageNumber, type, sortValue, sortOrder) {
            return this.request
                .get('/material')
                .query({
                    groupId,
                    name,
                    pageNumber,
                    type,
                    sortValue,
                    sortOrder
                });
        },

        /**
         * fixme 微信素材接口不该在邮件素材的接口文件里
         * 微信: 查询素材列表
         * @param groupId       素材分组：0：全部；-1：未分组；3：groupId
         * @param name          关键字
         * @param pageNumber    页码
         * @param type          类型：1：图文消息 2：图片
         */
        wechatMaterials(groupId, name, pageNumber, type) {
            return this.request
                .get('/wxmaterial')
                .query({
                    groupId,
                    name,
                    pageNumber,
                    type
                });
        },


        /**
         * fixme 微信素材接口不该在邮件素材的接口文件里
         * 微信：获取图文预览数据
         * @param id
         */
        wechatImgText(id) {
            return this.request
                .get(`/wxmaterial/${id}`);
        },


        /**
         * fixme 微信素材接口不该在邮件素材的接口文件里
         * 微信：获取图文副本
         * @param id
         */
        wechatImgTextCopy(id) {
            return this.request
                .get(`${LINKS.WECHAT_API_BASE}/api/wechat/v1/wxMaterialCopy/${id}`);
        },


        /**
         * fixme 微信素材接口不该在邮件素材的接口文件里
         * 微信：查询组列表
         * @param type
         */
        wechatMaterialGroup(type) {
            return this.request
                .get('/wxmaterial/group')
                .query({type});
        },


        /**
         * 批量获取素材内容，邮件的预览图地址、短信的文本内容
         * @param materialIds 素材id [1,2,3]
         * @param isCopy Boolean 副本
         */
        getMaterialsByIds(materialIds, isCopy) {
            return this.request
                .get('/material/content')
                .query({
                    materialIds,
                    isCopy
                });
        },


        /**
         * 获取素材详情
         * @param id
         */
        getMaterial(id) {
            return this.request
                .get(`/material/${id}`);
        },


        /**
         * 素材预览随机替换关键字
         * @param id
         * @return {*}
         */
        sampleMaterial(id) {
            return this.request
                .get(`/material/${id}/fieldReplace`);
        },


        /**
         * 获取素材副本
         * @param id
         */
        getMaterialCopy(id) {
            return this.request
                .get(`/materialCopy/${id}`);
        },


        /**
         * 更新
         * @param material
         * @return {Request|*}
         */
        update(material) {
            return this.request
                .put(`/material/${material.id}`)
                .send(material);
        },


        /**
         * 获取邮件素材内容
         * @param id
         */
        getEmailHtml(id) {
            return this.request.get(`/material/${id}/content`);
        },


        /**
         * 创建素材
         * @param groupId
         * @param type
         * @param materialContent
         * @param materialName
         * @return {Request|*}
         */
        create({groupId, type, materialContent, materialName, headId, footId}) {
            return this.request
                .post('/material')
                .send({
                    groupId,
                    type,
                    materialContent,
                    materialName,
                    headId,
                    footId
                });
        },


        /**
         * 复制一个素材
         * @param id
         * @return {Request|*}
         */
        duplicate(id) {
            return this.request
                .post(`/material/${id}`);
        },


        /**
         * 删除素材
         * @param id
         * @return {*|Request}
         */
        delMaterial(id) {
            return this.request.del(`/material/${id}`);
        },


        /**
         * 批量删除素材
         * @param ids
         * @returns {*}
         */
        batchDel(ids) {
            return this.request
                .delete(`/batchDel`)
                .send(ids);
        },


        /**
         * 上传邮件zip
         * @param file
         * @param progresser
         * @return {Request}
         */
        uploadZIP(file, progresser) {
            return this.request
                .post('/material/file')
                .attach('file', file)
                .on('progress', progresser);
        },


        /**
         * 获取ZIP的内容
         * @param file
         */
        getZIPContent(file) {
            return this.request
                .get('/material/file')
                .query({file});
        },


        /**
         * 保存单个彩信内容
         * @param mmsMaterial
         */
        createSingleMms(mmsMaterial) {
            return this.request
                .post('/material/mms')
                .send(mmsMaterial);
        },


        /**
         * 获取 单个彩信详情
         * @param id
         */
        getSingleMmsInfo(id) {
            return this.request
                .get(`/material/mms/${id}`);
        },


        /**
         * 删除 单个 彩信
         * @param id
         */
        deleteSingleMms(id) {
            return this.request.del(`/material/mms/${id}`);
        },


        /**
         * 更新 单个 彩信
         * @param materialMms
         */
        updateSingleMms(materialMms) {
            return this.request
                .put(`/material/mms/${materialMms.id}`)
                .send(materialMms);
        },


        /**
         * 排序
         * @param id
         * @param ids
         * @return {*}
         */
        sortMMSMaterials(id, ids) {
            return this.request
                .put(`/material/mms/${id}/sort`)
                .send(ids);
        },


        /**
         * 彩信上传图片
         * @param file
         * @return {*}
         */
        mmsUpload(file) {
            return this.request
                .post('/material/mms/file')
                .attach('file', file);
        },


        /**
         * 检查短信和彩信素材是否有测试权限
         * @param id    素材ID
         * @return {*}
         */
        checkMaterialTest(id) {
            return this.request
                .get('/checkMaterialTest')
                .query({id});
        },
    
    
        /**
         * 检查短信和彩信素材是否有预览权限
         * @param id    素材ID
         * @return {*}
         */
        checkMaterialPreview(id) {
            return this.request
                .get('/checkMaterialPreview')
                .query({id});
        },


        /**
         * 检查短信和彩信素材是否有编辑权限
         * @param id    素材ID
         * @return {*}
         */
        checkMaterialEdit(id) {
            return this.request
                .get('/checkMaterialEdit')
                .query({id});
        }
    }
});

