const App = () => import(/* webpackChunkName: "app", webpackPrefetch: true */ '../layout/index.vue');
// 分析模型
const EventAnalysis = () => import(/* webpackChunkName: "analysis", webpackPrefetch: true */ '../analysis/event-analysis/event-analysis.vue');
const EventUserList = () => import(/* webpackChunkName: "analysis", webpackPrefetch: true */ '../analysis/event-analysis/user-list.vue');
const AttributesAnalysis = () => import(/* webpackChunkName: "analysis", webpackPrefetch: true */ '../analysis/attribute-analysis/attribute-analysis.vue');
const AttributeUserList = () => import(/* webpackChunkName: "analysis", webpackPrefetch: true */ '../analysis/attribute-analysis/user-list.vue');
const UserBehavior = () => import(/* webpackChunkName: "analysis", webpackPrefetch: true */ '../analysis/user-behavior.vue');

// 用户分群
const UserGroup = () => import(/* webpackChunkName: "fans-group", webpackPrefetch: true */ '../analysis/user-group/user-group.vue');
const groupUserList = () => import(/* webpackChunkName: "fans-group", webpackPrefetch: true */ '../analysis/user-group/user-list.vue');
// const WIP = () => import(/* webpackChunkName: "app", webpackPrefetch: true */ '../common/components/work-in-progress.vue');

// 数据看板
const Boards = () => import(/* webpackChunkName: "board", webpackPrefetch: true */ '../analysis/board/boards.vue');
const Board = () => import(/* webpackChunkName: "board", webpackPrefetch: true */ '../analysis/board/board.vue');

// 可视化报告
const Visualization = () => import(/* webpackChunkName: "visualization", webpackPrefetch: true */ '../analysis/visualization/visualization.vue');

/**
 * 分析模型
 */
export default {
    path: '/analysis',
    name: 'analysis',
    meta: {name: 'Dmartech', auth: true},
    component: App,
    redirect: {name: 'home'},
    children: [
        // 事件分析
        {path: 'event', name: 'event', meta: {name: 'home.event'}, component: EventAnalysis},
        {path: 'event/fans', name: 'eventUserList', meta: {auth: 'event', name: 'home.userList'}, component: EventUserList},
        {
            path: 'event/fans/:id',
            name: 'eventUserBehavior',
            meta: {auth: 'event', name: 'home.userBehavior'},
            component: UserBehavior
        },
        // 属性分析
        {path: 'attribute', name: 'attribute', meta: {name: 'home.attribute'}, component: AttributesAnalysis,},
        {
            path: 'attribute/fans',
            name: 'attributeUserList',
            meta: {auth: 'attribute', name: 'home.userList'},
            component: AttributeUserList
        },
        {
            path: 'attribute/fans/:id',
            name: 'userBehavior',
            meta: {auth: 'attribute', name: 'home.userBehavior'},
            component: UserBehavior
        },
        // 漏斗分析
        // {path: 'funnel', name: 'funnelAnalysis', meta: {name: 'home.funnel'}, component: WIP},
        // 留存分析
        // {path: 'retention', name: 'retentionAnalysis', meta: {name: '留存分析'}, component: WIP},
        // 分布分析
        // {path: 'distribution', name: 'distributionAnalysis', meta: {name: '分布分析'}, component: WIP},
        // 用户路径
        // {path: 'path', name: 'userPath', meta: {name: '用户路径'}, component: WIP},
        // 网页热力分析
        // {path: 'web', name: 'webStatistic', meta: {name: '网页热力分析'}, component: WIP},
        // 用户分群
        {path: 'group/:id?', name: 'userGroup', meta: {name: 'home.userGroup'}, component: UserGroup,},
        {
            path: 'group/:id/fans',
            name: 'groupUserList',
            meta: {auth: 'userGroup', name: 'home.userList'},
            component: groupUserList
        },
        {
            path: 'group/:groupId/fans/:id',
            name: 'groupUserBehavior',
            meta: {auth: 'userGroup', name: 'home.userBehavior'},
            component: UserBehavior
        },
        // 数据看板
        {path: 'board', name: 'boards', meta: {name: 'home.board'}, component: Boards},
        {path: 'board/:id', name: 'board', meta: {auth: 'boards', name: 'home.boardDetail'}, component: Board},
        // 可视化报告
        {path: 'visualization', name: 'visualization', meta: {name: 'home.visualization'}, component: Visualization},
    ]
};
