var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sms-form material-form" }, [
    _c(
      "div",
      { staticClass: "content-view" },
      [_c("crumbs", { attrs: { crumbs: _vm.crumbs } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "page-head" }, [
      _c("div", { staticClass: "content-view" }, [
        _c("h1", [_vm._v(_vm._s(_vm._f("t")(_vm.$route.meta.name)))]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "pull-right preview-btn btn btn-md btn-orange",
            attrs: { type: "button" },
            on: { click: _vm.preview },
          },
          [
            _c("svg", { attrs: { "aria-hidden": "true" } }, [
              _c("use", { attrs: { "xlink:href": "#icon-search" } }),
            ]),
            _vm._v(
              "\n                " +
                _vm._s(_vm._f("t")("material.preview")) +
                "\n            "
            ),
          ]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "page-content" }, [
      _c("div", { staticClass: "content-view white-bg form" }, [
        _c("div", { staticClass: "form-inner-wrapper" }, [
          _c("div", { staticClass: "form-field flex" }, [
            _c(
              "label",
              { staticClass: "label", attrs: { for: "materialName" } },
              [_vm._v(_vm._s(_vm._f("t")("material.contentName")))]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.material.materialName,
                  expression: "material.materialName",
                  modifiers: { trim: true },
                },
              ],
              staticClass: "input",
              attrs: { type: "text", id: "materialName", maxlength: "50" },
              domProps: { value: _vm.material.materialName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.material,
                    "materialName",
                    $event.target.value.trim()
                  )
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-field" },
            [
              _c("span", { staticClass: "label placeholder" }, [
                _vm._v(_vm._s(_vm._f("t")("material.materialGroup"))),
              ]),
              _vm._v(" "),
              _c(
                "radio",
                {
                  attrs: { source: "use" },
                  model: {
                    value: _vm.groupSource,
                    callback: function ($$v) {
                      _vm.groupSource = $$v
                    },
                    expression: "groupSource",
                  },
                },
                [_vm._v(_vm._s(_vm._f("t")("material.selectGroup")))]
              ),
              _vm._v(" "),
              _c(
                "radio",
                {
                  attrs: {
                    source: "create",
                    disabled: _vm.$has("journey_material_add_group"),
                  },
                  model: {
                    value: _vm.groupSource,
                    callback: function ($$v) {
                      _vm.groupSource = $$v
                    },
                    expression: "groupSource",
                  },
                },
                [_vm._v(_vm._s(_vm._f("t")("material.createGroup")))]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.groupSource === "create"
            ? _c("div", { staticClass: "form-field" }, [
                _c("label", { staticClass: "label" }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.groupName,
                      expression: "groupName",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "input lg",
                  attrs: {
                    type: "text",
                    placeholder: _vm.$t("material.groupName"),
                    maxlength: "255",
                  },
                  domProps: { value: _vm.groupName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.groupName = $event.target.value.trim()
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "form-field" }, [
            _c(
              "label",
              { staticClass: "label placeholder", attrs: { for: "use" } },
              [
                _vm._v(
                  "\n                        " +
                    _vm._s(
                      _vm.groupSource === "create"
                        ? _vm.$t("common.parentGroup")
                        : _vm.$t("newJourney.selectGroup")
                    ) +
                    "\n                    "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "choose-parent",
                attrs: { id: "use" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.readyCheckGroup.apply(null, arguments)
                  },
                },
              },
              [
                _c("label", { staticClass: "select-text" }, [
                  _vm._v(
                    "\n                            " +
                      _vm._s(
                        _vm.parentGroupName ||
                          _vm.$t("journey.journeyGroupHolder")
                      ) +
                      "\n                            "
                  ),
                  _c("span", {
                    staticClass: "select-icon icon icon-arrow-down",
                  }),
                ]),
                _vm._v(" "),
                _vm.groupSelectShow
                  ? _c(
                      "div",
                      {
                        staticClass: "search-tree-wrapper",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c("search-tree", {
                          attrs: {
                            searchTreeData: _vm.groupList,
                            treeActiveId: _vm.selectGroupId,
                            action: false,
                            levelStage: _vm.groupSource === "use",
                          },
                          on: { getTreeAct: _vm.getTreeAct },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-field flex" }, [
            _c("span", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm._f("t")("material.contents"))),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "field-group" },
              [
                _c("selector", {
                  staticClass: "overlap-right",
                  attrs: {
                    options: _vm.fields,
                    placeholder: _vm.$t("material.personalization"),
                  },
                  on: { select: _vm.insertField },
                  model: {
                    value: _vm.fields_id,
                    callback: function ($$v) {
                      _vm.fields_id = $$v
                    },
                    expression: "fields_id",
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.shortUrl,
                      expression: "shortUrl",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "input overlap-left overlap-right",
                  attrs: { type: "text", placeholder: "http://" },
                  domProps: { value: _vm.shortUrl },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.shortUrl = $event.target.value.trim()
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  staticClass: "field-tail-btn",
                  attrs: {
                    type: "button",
                    value: _vm.$t("material.insertLink"),
                  },
                  on: { click: _vm.insertUrl },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-field flex" }, [
            _c("span", { staticClass: "label placeholder" }),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.material.materialContent,
                  expression: "material.materialContent",
                },
              ],
              ref: "textarea",
              staticClass: "textarea",
              attrs: {
                rows: "12",
                placeholder:
                  _vm.$t("material.placeHolder") +
                  _vm.$t("controller.and") +
                  "“" +
                  _vm.UNSUBSCRIBE_SMS_POSTFIX +
                  "”" +
                  _vm.$t("material.text"),
              },
              domProps: { value: _vm.material.materialContent },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.material, "materialContent", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "content-count-text" }, [
            _vm._v(
              "\n                    " +
                _vm._s(_vm._f("t")("material.about")) +
                "\n                    "
            ),
            _c("span", { staticClass: "orange-text" }, [
              _vm._v(_vm._s(_vm.contentCount)),
            ]),
            _vm._v(
              " " + _vm._s(_vm._f("t")("material.char")) + "\n                "
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-panel" },
        [
          _c("input", {
            staticClass: "cancel-btn",
            attrs: { type: "button", value: _vm.$t("common.cancel") },
            on: { click: _vm.cancel },
          }),
          _vm._v(" "),
          !_vm.material.id
            ? [
                _vm._v(" " + _vm._s(_vm._f("t")("material.createNewSms"))),
                _c("span", { staticClass: "step" }, [
                  _vm._v(_vm._s(_vm._f("t")("material.design"))),
                ]),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            staticClass: "confirm-btn btn btn-theme",
            attrs: { type: "button", value: _vm.$t("material.done") },
            on: { click: _vm.done },
          }),
          _vm._v(" "),
          !_vm.material.id
            ? _c("input", {
                staticClass: "confirm-btn btn",
                attrs: { type: "button", value: _vm.$t("material.previous") },
                on: { click: _vm.cancel },
              })
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }