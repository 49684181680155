// const App = () => import(/* webpackChunkName: "app", webpackPrefetch: true */ '../layout/index.vue');
const Setting = () => import(/* webpackChunkName: "org-setting", webpackPrefetch: true */'../setting/setting.vue');
const orgMember = () => import(/* webpackChunkName: "org-setting", webpackPrefetch: true */'../setting/organization/org-member.vue');
const orgMemberForm = () => import(/* webpackChunkName: "setting", webpackPrefetch: true */'../setting/organization/org-member-form.vue');
const PrivilegeSecurity = () => import(/* webpackChunkName: "org-setting", webpackPrefetch: true */ '../setting/privilege-security/privilege-security-list.vue');
const SecurityManagement = () => import(/* webpackChunkName: "org-setting", webpackPrefetch: true */ '../setting/privilege-security/security-group.vue');
const dataSharing = () => import(/* webpackChunkName: "org-setting", webpackPrefetch: true */ '../setting/data-sharing/data-sharing.vue');
const dataSharingForm = ()=> import(/* webpackChunkName: "org-setting", webpackPrefetch: true */ '../setting/data-sharing/data-sharing-form.vue');
const dataResourceSharingForm = ()=> import(/* webpackChunkName: "org-setting", webpackPrefetch: true */ '../setting/data-sharing/data-resource-sharing-form.vue');
const sendChannel = () => import(/* webpackChunkName: "org-setting", webpackPrefetch: true */ '../setting/send-channel/send-channel.vue');
const sentFrequency = () => import(/* webpackChunkName: "org-setting", webpackPrefetch: true */ '../setting/frequency/sent-frequency.vue');
const systemSettings = () => import(/* webpackChunkName: "org-setting", webpackPrefetch: true */ '../setting/system-settings/system-settings.vue');
const WeChatPublicNo = () => import(/* webpackChunkName: "org-setting", webpackPrefetch: true */ '../setting/WeChat-no/WeChat-no.vue');
const BaseInfo = () => import(/* webpackChunkName: "org-setting", webpackPrefetch: true */ '../setting/privilege-security/security-group/base-info.vue');
const OperateAuthority = () => import(/* webpackChunkName: "org-setting", webpackPrefetch: true */ '../setting/privilege-security/security-group/operate-authority.vue');
const ManagementAuthority = () => import(/* webpackChunkName: "org-setting", webpackPrefetch: true */ '../setting/privilege-security/security-group/manage-authority.vue');
const DataAuthority = () => import(/* webpackChunkName: "org-setting", webpackPrefetch: true */ '../setting/privilege-security/security-group/data-authority.vue');
const FieldPermission = () => import(/* webpackChunkName: "org-setting", webpackPrefetch: true */ '../setting/privilege-security/security-group/field-permission.vue');

/**
 * 企业设置
 */
export default {
    name: 'orgSetting',
    path: '/orgSetting',
    meta: {auth: true, name: 'EnterPrise.enterpriseSettings'},
    component: Setting,
    redirect: {name: 'orgMember'},
    children: [
        // 组织成员
        {
            path: 'orgMember',
            name: 'orgMember',
            meta: {name: 'EnterPrise.orgMember', auth: 'orgSetting'},
            component: orgMember
        },
        {
            path: 'orgMember/:id/add',
            name: 'addMemberForm',
            meta: {auth: 'orgMember', name: 'EnterPrise.addUser'},
            component: orgMemberForm
        },
        {
            path: 'orgMember/:id/edit',
            name: 'editMemberForm',
            meta: {auth: 'orgMember', name: 'EnterPrise.edit'},
            component: orgMemberForm
        },
        // 权限管理
        {
            path: 'privilegeSecurity',
            name: 'privilegeSecurity',
            meta: {name: 'privilege.privilegeSecurity', auth: 'privilegeSecurity'},
            component: PrivilegeSecurity
        },
        {
            path: 'privilegeSecurity/edit/:id',
            name: 'securityManagement',
            meta: {name: 'privilege.edit', auth: 'privilegeSecurity'},
            component: SecurityManagement,
            redirect: {name: 'baseInfo'},
            children: [
                // 基本信息
                {
                    path: 'baseInfo',
                    name: 'baseInfo',
                    meta: {name: 'privilege.baseInfo', auth: 'orgSetting'},
                    component: BaseInfo
                },
                // 操作权限
                {
                    path: 'operateAuthority',
                    name: 'operateAuthority',
                    meta: {name: 'privilege.operateAuthority', auth: 'orgSetting'},
                    component: OperateAuthority
                },
                // 管理权限
                {
                    path: 'managementAuthority',
                    name: 'managementAuthority',
                    meta: {name: 'privilege.managementAuthority', auth: 'orgSetting'},
                    component: ManagementAuthority
                },
                // 数据权限
                {
                    path: 'dataAuthority',
                    name: 'dataAuthority',
                    meta: {name: 'privilege.dataAuthority', auth: 'orgSetting'},
                    component: DataAuthority
                },
                // 字段权限
                {
                    path: 'fieldPermission',
                    name: 'fieldPermission',
                    meta: {name: 'privilege.fieldPermission', auth: 'orgSetting'},
                    component: FieldPermission
                },
            ]
        },
        // 数据共享
        {
            path: 'dataSharing',
            name: 'dataSharing',
            meta: {name: 'sharing.title', auth: 'dataSharing'},
            component: dataSharing
        },
        {
            path: 'dataSharing/:id/customerSharingAdd',
            name: 'addSharingForm',
            meta: {name: 'sharing.addRule', auth: 'dataSharing'},
            component: dataSharingForm
        },
        {
            path: 'dataSharing/:id/customerSharingEdit',
            name: 'editSharingForm',
            meta: {name: 'sharing.editRule', auth: 'dataSharing'},
            component: dataSharingForm
        },
        {
            path: 'dataSharing/:id/resourceSharingAdd',
            name: 'addResourceSharingForm',
            meta: {name: 'sharing.addRule', auth: 'dataSharing'},
            component: dataResourceSharingForm
        },
        {
            path: 'dataSharing/:id/resourceSharingEdit',
            name: 'editResourceSharingForm',
            meta: {name: 'sharing.editRule', auth: 'dataSharing'},
            component: dataResourceSharingForm
        },
        
        // 发送通道
        {
            path: 'sendChannel',
            name: 'sendChannel',
            meta: {name: 'sendChannel.title', auth: 'sendChannel'},
            component: sendChannel
        },
        // 发送频次
        {
            path: 'sentFrequency',
            name: 'sentFrequency',
            meta: {name: 'frequency.title', auth: 'sentFrequency'},
            component: sentFrequency
        },
        // 系统设置
        {
            path: 'systemSettings',
            name: 'systemSettings',
            meta: {name: 'systemSet.title', auth: 'systemSettings'},
            component: systemSettings
        },
        // 微信公众号
        {
            path: 'WeChatPublicNo',
            name: 'WeChatPublicNo',
            meta: {name: 'wechatNo.title', auth: 'WeChatPublicNo'},
            component: WeChatPublicNo
        },
    ]
};
