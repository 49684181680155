var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "modal",
        {
          staticClass: "material-test",
          attrs: { title: _vm.title, size: "lg" },
          on: { close: _vm.close },
          scopedSlots: _vm._u(
            [
              {
                key: "buttons",
                fn: function () {
                  return [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-md btn-white",
                        attrs: { type: "button" },
                        on: { click: _vm.close },
                      },
                      [_vm._v(_vm._s(_vm._f("t")("common.cancel")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-md btn-theme",
                        attrs: { type: "button", disabled: _vm.loading },
                        on: { click: _vm.testSend },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("t")("material.testNow")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            825644754
          ),
        },
        [
          _vm.loading
            ? _c("inline-loading")
            : [
                _vm.material.type === 0 || _vm.material.type === 2
                  ? [
                      _c("div", { staticClass: "form-field flex" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("t")(
                                  _vm.material.type === 0
                                    ? "controller.smsSignature"
                                    : "controller.mmsSignature"
                                )
                              ) +
                              "\n                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field-group" },
                          [
                            _vm.material.type === 0
                              ? _c("selector", {
                                  staticClass: "xl",
                                  attrs: {
                                    options: _vm.sendoutList,
                                    search: _vm.sendoutList.length > 10,
                                  },
                                  model: {
                                    value: _vm.SMS.sendoutId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.SMS, "sendoutId", $$v)
                                    },
                                    expression: "SMS.sendoutId",
                                  },
                                })
                              : _c("selector", {
                                  staticClass: "xl",
                                  attrs: {
                                    options: _vm.sendoutList,
                                    search: _vm.sendoutList.length > 10,
                                  },
                                  model: {
                                    value: _vm.MMS.sendoutId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.MMS, "sendoutId", $$v)
                                    },
                                    expression: "MMS.sendoutId",
                                  },
                                }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.material.type === 2
                        ? _c("div", { staticClass: "form-field flex" }, [
                            _c(
                              "label",
                              {
                                staticClass: "label",
                                attrs: { for: "mmsSubjectInput" },
                              },
                              [_vm._v(_vm._s(_vm.$t("controller.mmsSubject")))]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "field-group" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: _vm.MMS.mailSubject,
                                    expression: "MMS.mailSubject",
                                    modifiers: { trim: true },
                                  },
                                ],
                                ref: "mmsSubjectInput",
                                staticClass: "input xl",
                                attrs: {
                                  type: "text",
                                  id: "mmsSubjectInput",
                                  maxlength: "66",
                                  placeholder: _vm.$t(
                                    "controller.mmsSubject_placeholder"
                                  ),
                                },
                                domProps: { value: _vm.MMS.mailSubject },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.MMS,
                                      "mailSubject",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-field flex" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm._f("t")("material.testNo"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field-group" },
                          [
                            _c("p", { staticClass: "light-content-text" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm._f("t")("material.testNoTipA")) +
                                  "\n                        "
                              ),
                              _c("strong", { staticClass: "theme-text" }, [
                                _vm._v(_vm._s(_vm.uniqueValidTels.length)),
                              ]),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm._f("t")("material.testNoTipB")) +
                                  "\n                        "
                              ),
                              _c("span", {
                                directives: [
                                  {
                                    name: "title",
                                    rawName: "v-title:top",
                                    value: _vm.$t("material.testNotice"),
                                    expression: "$t('material.testNotice')",
                                    arg: "top",
                                  },
                                ],
                                staticClass: "icon icon-help",
                              }),
                            ]),
                            _vm._v(" "),
                            _vm.material.type === 0
                              ? _vm._l(_vm.SMS.testTels, function (testTel) {
                                  return _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: testTel.tel,
                                        expression: "testTel.tel",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    key: testTel.id,
                                    staticClass: "input lg test-input",
                                    attrs: {
                                      type: "number",
                                      placeholder: _vm.$t(
                                        "material.mobileNoHolder"
                                      ),
                                    },
                                    domProps: { value: testTel.tel },
                                    on: {
                                      keydown: _vm.keydownChecker,
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.addTelNumber.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          testTel,
                                          "tel",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  })
                                })
                              : _vm._l(_vm.MMS.testTels, function (testTel) {
                                  return _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.trim",
                                        value: testTel.tel,
                                        expression: "testTel.tel",
                                        modifiers: { trim: true },
                                      },
                                    ],
                                    key: testTel.id,
                                    staticClass: "input lg test-input",
                                    attrs: {
                                      type: "number",
                                      placeholder: _vm.$t(
                                        "material.mobileNoHolder"
                                      ),
                                    },
                                    domProps: { value: testTel.tel },
                                    on: {
                                      keydown: _vm.keydownChecker,
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        return _vm.addTelNumber.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          testTel,
                                          "tel",
                                          $event.target.value.trim()
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  })
                                }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "action-text",
                                on: { click: _vm.addTelNumber },
                              },
                              [
                                _vm._v(
                                  "+ " +
                                    _vm._s(_vm._f("t")("material.addTestNo"))
                                ),
                              ]
                            ),
                          ],
                          2
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.material.type === 1 ||
                _vm.material.type === 3 ||
                _vm.material.type === 6
                  ? [
                      _c("div", { staticClass: "form-field flex" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm._f("t")("material.address"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field-group" },
                          [
                            _c("selector", {
                              staticClass: "xl",
                              attrs: {
                                options: _vm.senderAddresses,
                                search: _vm.senderAddresses.length > 10,
                              },
                              on: { select: _vm.changeAddress },
                              model: {
                                value: _vm.Email.sendAddressId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.Email, "sendAddressId", $$v)
                                },
                                expression: "Email.sendAddressId",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-field flex" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm._f("t")("material.sender"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field-group" },
                          [
                            _c("selector", {
                              staticClass: "xl",
                              attrs: { options: _vm.senderList, search: "" },
                              on: { select: _vm.changeSenders },
                              model: {
                                value: _vm.Email.senderId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.Email, "senderId", $$v)
                                },
                                expression: "Email.senderId",
                              },
                            }),
                            _vm._v(" "),
                            _c("button", {
                              directives: [
                                {
                                  name: "title",
                                  rawName: "v-title:top",
                                  value: _vm.$t("common.edit"),
                                  expression: "$t('common.edit')",
                                  arg: "top",
                                },
                              ],
                              staticClass: "action-icon icon icon-pencil",
                              attrs: {
                                type: "button",
                                disabled: _vm.$has("journey_edit_send_user"),
                              },
                              on: { click: _vm.showEditSender },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-field flex" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm._f("t")("material.testSubject"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field-group" },
                          [
                            _vm.isADMail
                              ? _c(
                                  "span",
                                  { staticClass: "mail-subject-ad-prefix" },
                                  [_vm._v("(AD)")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.trim",
                                  value: _vm.mailSubject,
                                  expression: "mailSubject",
                                  modifiers: { trim: true },
                                },
                              ],
                              ref: "mailSubjectInput",
                              staticClass: "input lg",
                              attrs: { type: "text", maxlength: "100" },
                              domProps: { value: _vm.mailSubject },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.mailSubject = $event.target.value.trim()
                                },
                                blur: function ($event) {
                                  return _vm.$forceUpdate()
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("selector", {
                              attrs: {
                                options: _vm.selectFields,
                                placeholder: _vm.$t("controller.insert"),
                              },
                              on: {
                                select: function ($event) {
                                  return _vm.insertField($event)
                                },
                              },
                            }),
                            _vm._v(
                              "\n                     \n                    "
                            ),
                            _c(
                              "checkbox",
                              {
                                model: {
                                  value: _vm.isADMail,
                                  callback: function ($$v) {
                                    _vm.isADMail = $$v
                                  },
                                  expression: "isADMail",
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("t")("controller.insertADPrefix")
                                  )
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("i", {
                              directives: [
                                {
                                  name: "title",
                                  rawName: "v-title:bottom",
                                  value: _vm.$t("controller.emailSubjectADTip"),
                                  expression:
                                    "$t('controller.emailSubjectADTip')",
                                  arg: "bottom",
                                },
                              ],
                              staticClass: "action-icon icon icon-help",
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-field flex" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v(_vm._s(_vm._f("t")("material.testEmail"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "field-group" },
                          [
                            _c("p", { staticClass: "light-content-text" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm._f("t")("material.testEmailTipA")
                                  ) +
                                  "\n                        "
                              ),
                              _c("strong", { staticClass: "theme-text" }, [
                                _vm._v(_vm._s(_vm.uniqueValidEmails.length)),
                              ]),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm._f("t")("material.testEmailTipB")
                                  ) +
                                  "\n                        "
                              ),
                              _c("span", {
                                directives: [
                                  {
                                    name: "title",
                                    rawName: "v-title:top",
                                    value: _vm.$t("material.testNotice"),
                                    expression: "$t('material.testNotice')",
                                    arg: "top",
                                  },
                                ],
                                staticClass: "icon icon-help",
                              }),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.Email.testMails, function (number) {
                              return _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.trim",
                                    value: number.address,
                                    expression: "number.address",
                                    modifiers: { trim: true },
                                  },
                                ],
                                staticClass: "input xl test-input",
                                attrs: { type: "text" },
                                domProps: { value: number.address },
                                on: {
                                  keydown: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.addTelNumber.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      number,
                                      "address",
                                      $event.target.value.trim()
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              })
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "action-text",
                                on: {
                                  click: function ($event) {
                                    return _vm.addTelNumber()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "+ " +
                                    _vm._s(_vm._f("t")("material.addTestEmail"))
                                ),
                              ]
                            ),
                          ],
                          2
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ],
          _vm._v(" "),
          _vm._v(" "),
          _c("edit-sender-modal", {
            attrs: {
              editSenderShow: _vm.editSenderShow,
              senderList: _vm.senderList,
            },
            on: { close: _vm.closeEditSender, change: _vm.fetchSenderList },
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }