var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "calendar" },
    [
      _c("input", {
        ref: "target",
        attrs: {
          type: "text",
          placeholder: _vm.placeholder,
          readonly: _vm.readonly,
        },
        domProps: { value: _vm.value },
      }),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }