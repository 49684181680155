<template>
    <div class="pager" :class="size" v-if="total > 0">
        <slot></slot>
        <ul class="pages">
            <li class="pages-prev">
                <span class="icon icon-arrow-left" v-if="pageNow === 1"></span>
                <a v-else class="icon icon-arrow-left" href="javascript:void(0);" @click="prevClick"></a>
            </li>
            <!--如果只有一页就不显示固定的第一个分页按钮了,避免重复-->
            <template v-if="pageTotal > 1">
                <li v-for="i in pageBegin" class="pages-li" :class="{active:i == pageNow}">
                    <span v-if="i == pageNow" v-text="i"></span>
                    <a v-else @click="pageClick(i)" v-text="i"></a>
                </li>
            </template>
            <li class="left-rest" v-if="ellipsis[0] > slider">
                <span>...</span>
            </li>
            <li v-for="i in pageMiddle" class="pages-li" :class="{active:i == pageNow}">
                <span v-if="i == pageNow" v-text="i"></span>
                <a v-else @click="pageClick(i)" v-text="i"></a>
            </li>
            <li class="right-rest" v-if="pageTotal - ellipsis[1] > slider">
                <span>...</span>
            </li>
            <li v-for="i in pageEnd" class="pages-li" :class="{active:i == pageNow}">
                <span v-if="i == pageNow" v-text="i"></span>
                <a v-else @click="pageClick(i)" v-text="i"></a>
            </li>
            <li class="pages-next">
                <span v-if="pageNow == pageTotal" class="icon icon-arrow-right"></span>
                <a v-else class="icon icon-arrow-right" @click="nextClick"></a>
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
        name: 'pager',
        props: {
            // 总页数
            total: {
                type: [Number, String],
                required: true
            },
            // 当前页
            now: {
                type: [Number, String],
                default: 1
            },
            size: { // big, sm
                type: String,
                default: 'big'
            },
            length: {
                type: Number,
                default: 8
            }
        },
        data() {
            return {
                //总页数
                pageTotal: this.total,
                //输入的页码
                pageNum: '',
                //显示分页按钮的个数
                // length: 8,
                //前后固定的分页按钮个数
                slider: 1
            };
        },
        watch: {
            total(val) {
                let page_total = parseInt(val);
                page_total = (isNaN(page_total) || page_total < 1) ? 1 : page_total;
                this.pageTotal = page_total;
            },
        },
        computed: {
            //当前页
            pageNow() {
                let page_now = parseInt(this.now);
                page_now = (isNaN(page_now) || this.total < 2 || page_now < 1) ? 1 : page_now;
                page_now = page_now > this.total ? this.total : page_now;
                return page_now;
            },
            //前边显示固定分页数
            pageBegin() {
                return Math.min(this.slider, this.ellipsis[0]);
            },
            //中间显示分页数
            pageMiddle() {
                let arr = [];
                for (let i = this.ellipsis[0] + 1; i <= this.ellipsis[1]; i++) {
                    arr.push(i);
                }
                return arr;
            },
            //后边显示分页数
            pageEnd() {
                let arr = [];
                for (let i = this.ellipsis[2] + 1; i <= this.pageTotal; i++) {
                    arr.push(i);
                }
                return arr;
            },
            /**
             * 出现三个点时的分页的范围
             * @returns {*[]}
             * begin: 开始页码
             * end: 结束页码
             * end_max: 结束页码的最大值
             */
            ellipsis() {
                let end_max = this.pageTotal - this.slider;
                let begin = this.pageNow - (this.length / 2) + this.slider;
                begin = begin < 1 ? 1 : begin;
                let end = begin + this.length - 2 * this.slider;
                //当begin达到最小值后需要根据begin重新计算end以保证显示的分页按钮个数不变
                end = begin < this.slider ? (end + this.slider - begin) : end;
                if (end >= end_max) {
                    end = end_max;
                    //当end达到最大值后需要根据end重新计算begin以保证显示的分页按钮个数不变
                    begin = (end - this.length + 2 * this.slider) < 1 ? 1 : (end - this.length + 2 * this.slider);
                }
                return [begin, end, end_max];
            }
        },
        methods: {
            //上一页
            prevClick() {
                let no = this.pageNow - 1;
                if (no < 1) no = 1;
                this.$emit('page', no);
            },
            //下一页
            nextClick() {
                let no = this.pageNow + 1;
                if (no > this.pageTotal) no = this.pageTotal;
                this.$emit('page', no);
            },
            //点击页码
            pageClick(no) {
                this.$emit('page', no);
            }
        }
    };
</script>
<style lang="scss" type="text/scss" rel="stylesheet/scss">
    @import '../scss/base/variables';
    @import '../scss/base/mixin';

    .pager {
        text-align: right;
        @include clearfix();

        .pages {
            float: right;
            margin: 8px auto 8px 8px;
            padding: 8px 0;
            @include user-select(none);
            @include clearfix();
        }

        &.big {
            $size: 32px;
            line-height: $size + 8px * 4;

            .pages {
                li {
                    @include box-sizing();
                    float: left;
                    min-width: $size;
                    height: $size;
                    border: 1px solid $border-color;
                    line-height: $size - 2px;
                    text-align: center;
                    background-color: white;
                    margin-right: 8px;
                    @include border-radius(4px);

                    &.active {
                        color: $green;
                        border-color: $green;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    span {
                        @include box-sizing();
                        display: block;
                        width: 100%;
                        padding: 0 5px;
                        height: 100%;
                        cursor: default;
                    }

                    a {
                        @include box-sizing();
                        display: block;
                        width: 100%;
                        padding: 0 5px;
                        height: 100%;
                        color: $color-content;
                        cursor: pointer;

                        &.hover {
                            color: $theme;
                        }
                    }
                }
            }

            .left-rest,
            .right-rest {
                border: none;
                background: transparent;
            }
        }

        &.sm {
            $size: 30px;
            line-height: $size + 8px * 4;

            .pages {
                li {
                    @include box-sizing();
                    float: left;
                    min-width: $size;
                    height: $size;
                    line-height: $size;
                    text-align: center;
                    background-color: #fff;

                    &.active {
                        color: $theme;
                    }

                    &.pages-li {
                        a:hover {
                            text-decoration: underline;
                        }
                    }

                    span {
                        cursor: default;
                    }

                    a {
                        display: block;
                        width: 100%;
                        height: 100%;
                        color: $color-content;

                        &:hover {
                            color: $theme;
                        }
                    }
                }
            }
        }

        .pages-prev,
        .pages-next {
            span {
                color: $disabled;
                cursor: not-allowed;
            }
        }
    }
</style>
