var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-empty" },
    [
      _vm.action
        ? [
            _vm._v(_vm._s(_vm._f("t")("common.noResult")) + "，\n        "),
            _c(
              "span",
              {
                staticClass: "action-text",
                on: {
                  click: function ($event) {
                    return _vm.$emit("action")
                  },
                },
              },
              [_vm._v(_vm._s(_vm.action))]
            ),
          ]
        : [_vm._v(_vm._s(_vm._f("t")("common.noResult")))],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }