var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.total > 0
    ? _c(
        "div",
        { staticClass: "pager", class: _vm.size },
        [
          _vm._t("default"),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "pages" },
            [
              _c("li", { staticClass: "pages-prev" }, [
                _vm.pageNow === 1
                  ? _c("span", { staticClass: "icon icon-arrow-left" })
                  : _c("a", {
                      staticClass: "icon icon-arrow-left",
                      attrs: { href: "javascript:void(0);" },
                      on: { click: _vm.prevClick },
                    }),
              ]),
              _vm._v(" "),
              _vm.pageTotal > 1
                ? _vm._l(_vm.pageBegin, function (i) {
                    return _c(
                      "li",
                      {
                        staticClass: "pages-li",
                        class: { active: i == _vm.pageNow },
                      },
                      [
                        i == _vm.pageNow
                          ? _c("span", { domProps: { textContent: _vm._s(i) } })
                          : _c("a", {
                              domProps: { textContent: _vm._s(i) },
                              on: {
                                click: function ($event) {
                                  return _vm.pageClick(i)
                                },
                              },
                            }),
                      ]
                    )
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.ellipsis[0] > _vm.slider
                ? _c("li", { staticClass: "left-rest" }, [
                    _c("span", [_vm._v("...")]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.pageMiddle, function (i) {
                return _c(
                  "li",
                  {
                    staticClass: "pages-li",
                    class: { active: i == _vm.pageNow },
                  },
                  [
                    i == _vm.pageNow
                      ? _c("span", { domProps: { textContent: _vm._s(i) } })
                      : _c("a", {
                          domProps: { textContent: _vm._s(i) },
                          on: {
                            click: function ($event) {
                              return _vm.pageClick(i)
                            },
                          },
                        }),
                  ]
                )
              }),
              _vm._v(" "),
              _vm.pageTotal - _vm.ellipsis[1] > _vm.slider
                ? _c("li", { staticClass: "right-rest" }, [
                    _c("span", [_vm._v("...")]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.pageEnd, function (i) {
                return _c(
                  "li",
                  {
                    staticClass: "pages-li",
                    class: { active: i == _vm.pageNow },
                  },
                  [
                    i == _vm.pageNow
                      ? _c("span", { domProps: { textContent: _vm._s(i) } })
                      : _c("a", {
                          domProps: { textContent: _vm._s(i) },
                          on: {
                            click: function ($event) {
                              return _vm.pageClick(i)
                            },
                          },
                        }),
                  ]
                )
              }),
              _vm._v(" "),
              _c("li", { staticClass: "pages-next" }, [
                _vm.pageNow == _vm.pageTotal
                  ? _c("span", { staticClass: "icon icon-arrow-right" })
                  : _c("a", {
                      staticClass: "icon icon-arrow-right",
                      on: { click: _vm.nextClick },
                    }),
              ]),
            ],
            2
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }