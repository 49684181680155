var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.has
    ? _c(
        "label",
        { staticClass: "switch-button dis", class: { active: _vm.value } },
        [_vm._t("default")],
        2
      )
    : _c(
        "label",
        {
          staticClass: "switch-button",
          class: { active: _vm.value },
          on: {
            click: function ($event) {
              return _vm.$emit("input", !_vm.value)
            },
          },
        },
        [_vm._t("default")],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }