var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "crumbs" },
    [
      _vm._l(_vm.routes, function (route, count) {
        return [
          _c("router-link", {
            attrs: { to: route },
            domProps: { textContent: _vm._s(route.text) },
          }),
          _vm._v(" "),
          count !== _vm.routes.length - 1 ? [_vm._v(" / ")] : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }