var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("svg", { staticClass: "inline-loading", class: _vm.offset }, [
    _c("circle", { attrs: { cx: "4", cy: "20", r: "4" } }),
    _vm._v(" "),
    _c("circle", { attrs: { cx: "9", cy: "9", r: "4" } }),
    _vm._v(" "),
    _c("circle", { attrs: { cx: "20", cy: "4", r: "4" } }),
    _vm._v(" "),
    _c("circle", { attrs: { cx: "31", cy: "9", r: "4" } }),
    _vm._v(" "),
    _c("circle", { attrs: { cx: "36", cy: "20", r: "4" } }),
    _vm._v(" "),
    _c("circle", { attrs: { cx: "31", cy: "31", r: "4" } }),
    _vm._v(" "),
    _c("circle", { attrs: { cx: "20", cy: "36", r: "4" } }),
    _vm._v(" "),
    _c("circle", { attrs: { cx: "9", cy: "31", r: "4" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }