var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass: "radio",
      class: {
        disabled: _vm.disabled,
        "text-appended": _vm.$slots.default,
        checked: _vm.checked,
      },
      on: { click: _vm.toggle },
    },
    [
      _c("svg", { attrs: { "aria-hidden": "true" } }, [
        _c("use", {
          attrs: {
            "xlink:href": "#icon-radio" + (_vm.checked ? "-check" : ""),
          },
        }),
      ]),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }