var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c("loading")
    : _c(
        "div",
        { staticClass: "material-preview-wrapper" },
        [
          _vm.toolbar &&
          (_vm.material.type === 1 ||
            _vm.material.type === 3 ||
            _vm.material.type === 6 ||
            _vm.material.isHF)
            ? _c("ul", { staticClass: "device" }, [
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "title",
                        rawName: "v-title:top",
                        value: _vm.$t("material.pcPreview"),
                        expression: "$t('material.pcPreview')",
                        arg: "top",
                      },
                    ],
                    staticClass: "pc",
                    class: { active: _vm.device === "pc" },
                    on: {
                      click: function ($event) {
                        _vm.device = "pc"
                      },
                    },
                  },
                  [_c("i", { staticClass: "icon icon-pc" })]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "title",
                        rawName: "v-title:top",
                        value: _vm.$t("material.mobilePreview"),
                        expression: "$t('material.mobilePreview')",
                        arg: "top",
                      },
                    ],
                    staticClass: "mobile",
                    class: { active: _vm.device === "mobile" },
                    on: {
                      click: function ($event) {
                        _vm.device = "mobile"
                      },
                    },
                  },
                  [_c("i", { staticClass: "icon icon-mobile" })]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.material.type === 1 ||
          _vm.material.type === 3 ||
          _vm.material.type === 6 ||
          _vm.material.isHF
            ? _c("iframe", {
                ref: "emailPreviewFrame",
                staticClass: "email-preview-frame",
                class: _vm.device,
                attrs: { srcdoc: _vm.content },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.material.type == 0
            ? _c("div", { staticClass: "sms-preview-frame" }, [
                _c("div", { staticClass: "sms-preview-frame-content" }, [
                  _c("div", { staticClass: "sms-preview-bubble" }, [
                    _vm._v(
                      _vm._s(_vm.signature ? "【" + _vm.signature + "】" : "")
                    ),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.UNSUBSCRIBE_SMS_POSTFIX_REGEXP.test(_vm.content)
                            ? _vm.content
                            : _vm.content + _vm.UNSUBSCRIBE_SMS_POSTFIX
                        )
                    ),
                    _c("i", { staticClass: "bubble-tail" }),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.material.type === 2 && !_vm.material.isHF
            ? _c("div", { staticClass: "mms-modal-preview-frame" }, [
                _c(
                  "div",
                  { staticClass: "mms-modal-preview" },
                  [
                    _vm.material.otherCount &&
                    _vm.material.otherCount.mmsContent
                      ? _vm._l(
                          _vm.material.otherCount.mmsContent,
                          function (mms, index) {
                            return _c("div", { key: mms.id }, [
                              _c("p", [
                                mms.pictureUrl
                                  ? _c("img", {
                                      attrs: { src: mms.pictureUrl },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    domProps: {
                                      textContent: _vm._s(
                                        _vm.sampleName
                                          ? _vm.randMmsContents[index]
                                          : mms.mmsContent
                                      ),
                                    },
                                  },
                                  [_vm._v(_vm._s(mms.mmsContent))]
                                ),
                                _vm._v(" "),
                                index ==
                                _vm.material.otherCount.mmsContent.length - 1
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(_vm.UNSUBSCRIBE_SMS_POSTFIX) +
                                          "\n                        "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                            ])
                          }
                        )
                      : _vm._l(_vm.material.materialMms, function (mms, index) {
                          return _c("div", { key: mms.id }, [
                            _c("p", [
                              mms.pictureUrl
                                ? _c("img", { attrs: { src: mms.pictureUrl } })
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "span",
                                {
                                  domProps: {
                                    textContent: _vm._s(
                                      _vm.sampleName
                                        ? _vm.randMmsContents[index]
                                        : mms.mmsContent
                                    ),
                                  },
                                },
                                [_vm._v(_vm._s(mms.mmsContent))]
                              ),
                              _vm._v(" "),
                              index == _vm.material.materialMms.length - 1
                                ? _c("span", [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(_vm.UNSUBSCRIBE_SMS_POSTFIX) +
                                        "\n                        "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ])
                        }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.material.type === 5
            ? _c("wechat-preview", { attrs: { previewItem: _vm.material } })
            : _vm._e(),
          _vm._v(" "),
          _vm.toolbar &&
          (_vm.material.type === 0 ||
            _vm.material.type === 1 ||
            _vm.material.type === 3 ||
            _vm.material.type === 2 ||
            _vm.material.type === 6) &&
          !_vm.material.isHF
            ? _c("ul", { staticClass: "random-test-wrapper" }, [
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "title",
                        rawName: "v-title:left",
                        value:
                          _vm.$t("material.updateSample") +
                          `<br>` +
                          _vm.$t("material.current") +
                          `:${_vm.sampleName}]`,
                        expression:
                          "$t('material.updateSample')+`<br>`+$t('material.current')+`:${sampleName}]`",
                        arg: "left",
                      },
                    ],
                    staticClass: "random",
                    on: {
                      click: function ($event) {
                        return _vm.getRandomField()
                      },
                    },
                  },
                  [_c("i", { staticClass: "icon icon-touzi" })]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "title",
                        rawName: "v-title:left",
                        value: _vm.$t("material.testSend"),
                        expression: "$t('material.testSend')",
                        arg: "left",
                      },
                    ],
                    staticClass: "test",
                    on: {
                      click: function ($event) {
                        return _vm.$test(
                          _vm.material,
                          _vm.material.testSubject
                            ? _vm.material.testSubject
                            : ""
                        )
                      },
                    },
                  },
                  [_c("i", { staticClass: "icon icon-flask" })]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.material.type === 8
            ? _c("div", { staticClass: "wechat-template-preview" }, [
                _c(
                  "div",
                  { staticClass: "wechat-template" },
                  _vm._l(
                    _vm.material.sendContentArr || _vm.material.example,
                    function (list) {
                      return _c("div", {
                        domProps: { innerHTML: _vm._s(list) },
                      })
                    }
                  ),
                  0
                ),
              ])
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }