/**
 * Created by naeemo on 2017/4/7.
 */

import {
    ADD_A_TRANSLATION,
    CLEAR_USER_INFO,
    LOADED,
    LOADING,
    SET_AUTH_LOCK,
    SET_PLUGIN_ROUTES,
    SET_USER_INFO,
    UPDATE_USER_INFO,
    SET_TOKEN
} from './mutationTypes';
import {
    RECOVER_USER_INFO,
    SIGN_IN,
    SIGN_OUT,
    SIGNED_IN,
    SIGNED_OUT,
    UPDATE_ACCOUNT_BALANCE,
    UPDATE_MENU_AUTH,
    UPDATE_WECHAT_ACCOUNT_INFO,
    GET_TOKEN,
} from './actionTypes';
import {I18N_STORE_NAME, store as i18n, translate} from '../i18n/i18n';
import accountService from '../../layout/accountService';
import privilegeModel from '../../setting/privilege-security/privilegeModel';
import journeyModel from '../../journey/journeyModel';


/**
 * 用户信息缓存 key
 * @type {string}
 */
const USER_INFO_SESSION_KEY = String(process.env.NODE_ENV)
    .toUpperCase() + '_USER_INFO';

export default {
    strict: process.env.NODE_ENV !== 'production',
    state: {
        user: {
            authorization: '',  // JWT
            isSubscriptionAccount: false,   // 绑定公众号是否为微信订阅号
            cid: '',            // 用户ID
            authorizerInfoId: '',   // 联系人公众号authorizerInfoId
            isWx: 1,            // 账号是否开通了微信相关的功能
            email: '',          // 用户邮箱地址
            phone: '',          // 用户手机号
            areaCode: '',       // 区号
            iso2: '',           // 国家代码
            onlineTime: '',     // 上线时间
            userName: '',       // 用户名
            loginCount: 0,      // SCRM 登录次数
            id: '',
            isAdmin: 0,
            isAppreciationTemplate: 0,
            isAuthorty: 0,
            isTemplateShop: 0,
            isWxCount: '',
            smsSurplus: 0,      // 短信余量
            mmsSurplus: 0,
            smsAbroadSurplus: 0,
            mealName: '',       // 套餐版本
            validTime: '',      // 套餐有效期
            menus: {},          // 可见路由记录, key 是 routeName, value 是后台返回的 menu 对象
            permissions: {},    // 权限记录, key 是 flag权限唯一标识, value 是后台返回的 menu 对象
            canApproval: false, // 旅程审批权限（是否可编辑）
            approvalState: false, // 旅程审批状态
            canTWoFactor: false, // 两步验证权限（是否可编辑）
            twoFactorOpen: false,// 两步验证是否开启
            passTwoFactorOpen: false,  //两步验证(验证码)是否通过
            companyType: 0,     // 用户类型 0 普通用户 1 华为用户
        },
        pluginRoutes: [],       // 插件路由
        authLock: {
            title: '',
            message: ''
        },
        loading: false,          // 加载中
        tokenValue: {},
    },
    modules: {
        [I18N_STORE_NAME]: i18n
    },
    mutations: {
        [SET_USER_INFO](state, user) {
            state.user = user;
        },
        /**
         * 更新 user 对象上的部分字段
         * @param state
         * @param partialUser
         */
        [UPDATE_USER_INFO](state, partialUser) {
            for (const key in partialUser) {
                if (partialUser.hasOwnProperty(key)) {
                    state.user[key] = partialUser[key];
                }
            }
        },
        [CLEAR_USER_INFO](state) {
            for (let key in state.user) {
                if (state.user.hasOwnProperty(key))
                    state.user[key] = '';
            }

            localStorage.removeItem(USER_INFO_SESSION_KEY);
            localStorage.removeItem(SET_TOKEN);
        },
        [SET_AUTH_LOCK](state, {title = '', message = ''}) {
            state.authLock.title = title;
            state.authLock.message = message;
        },
        [SET_PLUGIN_ROUTES](state, routes) {
            state.pluginRoutes = routes;
        },
        [LOADING](state) {
            state.loading = true;
        },
        [LOADED](state) {
            state.loading = false;
        },
        [SET_TOKEN](state,tokenValue) {
            state.tokenValue = tokenValue;
        }
    },
    actions: {
        [RECOVER_USER_INFO]({commit}) {
            const userJSON = localStorage.getItem(USER_INFO_SESSION_KEY);
            if (userJSON) {
                const user = JSON.parse(userJSON);
                // 如果是插件菜单，注册其翻译到 i18n 子状态树上
                for (const key in user.menus) {
                    const menu = user.menus[key];
                    if (menu.isPlugin) {
                        menu.translationKey = 'plugin.' + menu.showName;
                        commit(ADD_A_TRANSLATION, {
                            translationKey: menu.translationKey,
                            translationObj: {en: menu.showName, 'zh-CN': menu.menuName}
                        });
                    }
                }

                commit(SET_USER_INFO, user);
            }
        },
        // 主动登入
        [SIGN_IN]({commit, state}, user) {
            // 1. 设置登录状态
            commit(SET_USER_INFO, user);
            localStorage.setItem(USER_INFO_SESSION_KEY, JSON.stringify(state.user));
            // 2. 通知其他标签页 'SIGNED_IN'
            localStorage.setItem(SIGNED_IN, (new Date).getTime());
            // 3. 诸葛io 识别用户
            // if (process.env.NODE_ENV === 'production') {
            //     let trimmedUser = Object.assign({}, user);
            //     delete trimmedUser.menus;
            //     delete trimmedUser.authentication;
            //     delete trimmedUser.authorizerInfoId;
            //     window.zhuge.identify(trimmedUser.userName, trimmedUser);
            // }
        },
        // 被动登录（其他标签页登录时，解锁当前视图）
        [SIGNED_IN]({commit, dispatch}) {
            // 设置登录状态
            dispatch(RECOVER_USER_INFO);
            // clear auth lock
            commit(SET_AUTH_LOCK, {});
        },
        // 主动登出
        [SIGN_OUT]({commit}) {
            // 1. 清除登录状态
            commit(CLEAR_USER_INFO);
            // 2. 通知其他标签页 SIGNED_IN
            localStorage.setItem(SIGNED_OUT, (new Date).getTime());
        },
        [UPDATE_ACCOUNT_BALANCE]({commit, state}) {
            // 获取短息余量
            return accountService
                .userProfile(state.user.email)
                .then(({body: {data: {profile: {smsSurplus, validTime, mealName, mmsSurplus, smsAbroadSurplus}}}}) => {
                    validTime = validTime || translate('home.indefinitely');
                    mealName = mealName || translate('home.basicPlan');
                    commit(UPDATE_USER_INFO, {smsSurplus, validTime, mealName, mmsSurplus, smsAbroadSurplus});
                });
        },
        [GET_TOKEN]({commit,state}) {
            // 获取短信报告登录token
            return journeyModel
                .getToken()
                .then(({body: {data}}) => {
                    commit(SET_TOKEN, data);
                    localStorage.setItem(GET_TOKEN, JSON.stringify(state.tokenValue));
                });
        },
        [UPDATE_WECHAT_ACCOUNT_INFO]({commit, state}) {
            // 1. 获取当前账号已授权的微信公众号列表
            return accountService
                .getWechatAccounts()
                .then(({body: {data: {authorizerInfoList: accounts}}}) => {
                    if (accounts.length > 0) {
                        // 2. 将授权公众号相关字段补充到用户信息中
                        commit(UPDATE_USER_INFO, {
                            authorizerInfoId: accounts[0].authorizerInfoId,
                            appId: accounts[0].WxAppId,
                            // 判断当前账号是否为微信订阅号
                            isSubscriptionAccount: accounts[0].serviceTypeInfo !== 2
                        });
                        localStorage.setItem(USER_INFO_SESSION_KEY, JSON.stringify(state.user));
                        // 3. 使用 'SIGNED_IN' 通知其他标签页更新
                        localStorage.setItem(SIGNED_IN, (new Date).getTime());
                    }
                });
        },
        [UPDATE_MENU_AUTH]({commit, state}) {
            // 登录后
            if (state.user && state.user.id) {
                // 获取旅程审批权限并存入缓存
                accountService
                    .getConfig(state.user.id)
                    .then(res => {
                        let canApproval = res.body.data.flag === 0; // 0:有编辑权限 1:没有
                        let approvalState = res.body.data.status === 0;// 0 启用 1 禁用
                        // 更新用户信息（是否有旅程审批权限）
                        commit(UPDATE_USER_INFO, {canApproval: canApproval, approvalState: approvalState});
                        let user = JSON.parse(localStorage.getItem(USER_INFO_SESSION_KEY));
                        user.canApproval = canApproval;
                        user.approvalState = approvalState;
                        localStorage.setItem(USER_INFO_SESSION_KEY, JSON.stringify(user));
                    });
            }
            // 主账号
            if (state.user.roleId === 0 || state.user.roleId === '') { // 主账号
                // 1. 获取当前用户的菜单权限
                return privilegeModel
                    .getRoleMenu(state.user.roleId, state.user.id)
                    .then(({body: {data: {menus = []}}}) => {
                        let records = {};
                        let permissions = {};
                        menus.forEach(menu => {
                            menu.showName = menu.showName.trim();

                            if (menu.showName) {
                                // 如果是插件菜单，注册其翻译到 i18n 子状态树上
                                if (menu.isPlugin) {
                                    menu.translationKey = 'plugin.' + menu.showName;
                                    commit(ADD_A_TRANSLATION, {
                                        translationKey: menu.translationKey,
                                        translationObj: {en: menu.showName, 'zh-CN': menu.menuName}
                                    });
                                }

                                const targetRecord = records[menu.showName];
                                if (targetRecord) {
                                    if (!targetRecord.isPlugin && menu.isPlugin) {
                                        // 注册 插件路由覆盖标准产品路由
                                        records[menu.showName] = menu;
                                        console.warn(`路由 ${menu.showName} 出现冲突，优先使用订制化功能，请检查套餐配置`);
                                    } else if (targetRecord.isPlugin && !menu.isPlugin) {
                                        console.warn(`路由 ${menu.showName} 出现冲突，优先使用订制化功能，请检查套餐配置`);
                                    } else {
                                        console.error(`路由 ${menu.showName} 重复定义`);
                                    }
                                } else {
                                    // 注册
                                    records[menu.showName] = menu;
                                }
                            }
                            // 注册权限
                            permissions[menu.flag] = menu;
                        });
                        // 2. 将菜单信息补充到用户信息中
                        commit(UPDATE_USER_INFO, {menus: records, permissions: permissions});
                        localStorage.setItem(USER_INFO_SESSION_KEY, JSON.stringify(state.user));
                        // 3. 使用 'SIGNED_IN' 通知其他标签页更新
                        localStorage.setItem(SIGNED_IN, (new Date).getTime());
                        return records;
                    });
            }
            // 子账号
            else {
                return privilegeModel
                    .getNewRoleMenu(state.user.id)
                    .then(({body: {data: {menus = []}}}) => {
                        // .then(({body: {data: {dataAuthorities = [], menus = []}}}) => {
                        // console.info(dataAuthorities);// todo dataAuthorities 数据权限
                        let records = {};
                        let permissions = {};
                        menus.forEach(menu => {
                            if (menu.showName) {
                                menu.showName = menu.showName.trim();
                                // 如果是插件菜单，注册其翻译到 i18n 子状态树上
                                if (menu.isPlugin) {
                                    menu.translationKey = 'plugin.' + menu.showName;
                                    commit(ADD_A_TRANSLATION, {
                                        translationKey: menu.translationKey,
                                        translationObj: {en: menu.showName, 'zh-CN': menu.menuName}
                                    });
                                }

                                const targetRecord = records[menu.showName];
                                if (targetRecord) {
                                    if (!targetRecord.isPlugin && menu.isPlugin) {
                                        // 注册 插件路由覆盖标准产品路由
                                        records[menu.showName] = menu;
                                        console.warn(`路由 ${menu.showName} 出现冲突，优先使用订制化功能，请检查套餐配置`);
                                    } else if (targetRecord.isPlugin && !menu.isPlugin) {
                                        console.warn(`路由 ${menu.showName} 出现冲突，优先使用订制化功能，请检查套餐配置`);
                                    } else {
                                        console.error(`路由 ${menu.showName} 重复定义`);
                                    }
                                } else {
                                    // 注册
                                    records[menu.showName] = menu;
                                }
                            }
                            // 注册权限
                            permissions[menu.flag] = menu;
                        });
                        // 2. 将菜单信息补充到用户信息中
                        commit(UPDATE_USER_INFO, {menus: records, permissions: permissions});
                        localStorage.setItem(USER_INFO_SESSION_KEY, JSON.stringify(state.user));
                        // 3. 使用 'SIGNED_IN' 通知其他标签页更新
                        localStorage.setItem(SIGNED_IN, (new Date).getTime());
                        return records;
                    });
            }
        }
    }
};
