var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade-in" } }, [
    _c("div", { staticClass: "loading" }, [
      _vm._v(" " + _vm._s(_vm._f("t")("common.loading"))),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }