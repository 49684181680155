var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", [
    _c(
      "div",
      {
        staticClass: "tree-group default-not-click",
        class: {
          treeactive: _vm.groupData.id === _vm.activeId,
          notClick: !_vm.levelStage && _vm.groupData.default === 1,
          noSelectable:
            _vm.groupData.noSelect === false && _vm.groupData.default === 2,
        },
        style: _vm.indent,
        on: {
          click: function ($event) {
            return _vm.singleMenu(_vm.groupData, _vm.action, _vm.moduleRange)
          },
        },
      },
      [
        _vm.hasChild
          ? _c("div", { staticClass: "tree-start-controller" }, [
              _c(
                "button",
                {
                  staticClass: "toggle-action-btn",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.toggle(_vm.groupData)
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.groupData.open,
                          expression: "!groupData.open",
                        },
                      ],
                      attrs: { "aria-hidden": "true" },
                    },
                    [_c("use", { attrs: { "xlink:href": "#icon-right" } })]
                  ),
                  _vm._v(" "),
                  _c(
                    "svg",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.groupData.open,
                          expression: "groupData.open",
                        },
                      ],
                      attrs: { "aria-hidden": "true" },
                    },
                    [_c("use", { attrs: { "xlink:href": "#icon-arrow-down" } })]
                  ),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "span",
          { staticClass: "group-name", attrs: { title: _vm.groupData.name } },
          [_vm._v(_vm._s(_vm.groupData.name))]
        ),
        _vm._v(" "),
        _vm.showMenu
          ? _c(
              "div",
              {
                staticClass: "operation hidden-menu-trigger",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "svg",
                  { staticClass: "gengduo", attrs: { "aria-hidden": "true" } },
                  [_c("use", { attrs: { "xlink:href": "#icon-gengduo" } })]
                ),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    staticClass: "hidden-menu",
                    on: {
                      click: function ($event) {
                        return _vm.singleMenu(
                          _vm.groupData,
                          _vm.action,
                          _vm.moduleRange
                        )
                      },
                    },
                  },
                  [
                    _vm.groupData.level < _vm.displayLevel + 1 &&
                    !_vm.$has(_vm.addGroup)
                      ? _c(
                          "li",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.actionGroup(
                                  "create",
                                  _vm.groupData,
                                  _vm.action
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm._f("t")("common.new")) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$has(_vm.modifyGroup)
                      ? _c(
                          "li",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.actionGroup(
                                  "edit",
                                  _vm.groupData,
                                  _vm.action
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm._f("t")("common.edit")) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$has(_vm.delGroup)
                      ? _c(
                          "li",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.actionGroup(
                                  "del",
                                  _vm.groupData,
                                  _vm.action
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm._f("t")("common.del")))]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _vm.hasChild
      ? _c(
          "ul",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.groupData.open,
                expression: "groupData.open",
              },
            ],
          },
          _vm._l(_vm.groupData.childs, function (item, index) {
            return _c("tree-menu", {
              key: index,
              attrs: {
                groupData: item,
                depth: _vm.depth + 1,
                action: _vm.action,
                levelStage: _vm.levelStage,
                activeId: _vm.activeId,
                moduleRange: _vm.moduleRange,
              },
              on: { choose: _vm.choose },
            })
          }),
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }