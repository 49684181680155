/**
 * Created by lvjuan on 2019/8/15.
 */
import Model from 'web-model';
import LINKS from '../../LINKS';
export default new Model({
    base: LINKS.GATE_ORIGIN + '/api/',
    api: {
        /**
         * 获取分组
         * @param keyWord
         */
        getAllGroup(keyWord) {
            return this.request
                .get('v1/group/searchGroup')
                .query({
                    keyWord
                });
        },

        /**
         * 保存/编辑分组
         * @param name
         * @param id
         */
        createGroup({name, id}) {
            return this.request
                .post('v1/group/addGroup')
                .send({
                    name, id
                });

        },

        /**
         * 删除分组
         * @param id
         * @returns {*}
         */
        deleteGroup({id}) {
            return this.request
                .delete(`v1/group/deleteGroup`)
                .query({id});
        },

        /**
         * 获取安全组列表
         * @param companyId
         * @param groupId
         * @param name
         * @param pageNumber
         */
        getSecurityGroups({companyId, groupId,name,pageNumber}) {
            return this.request
                .get(`findAllRoles`)
                .query({companyId, groupId,name,pageNumber});
        },

        /**
         * 新建安全组(基本信息)
         * @param companyId
         * @param copyRoleId
         * @param describe
         * @param groupId
         * @param roleName
         * @param status
         */
        createSaveRole({companyId, copyRoleId,describe,groupId,roleName,status}) {
            return this.request
                .post('saveRole')
                .send({
                    companyId, copyRoleId,describe,groupId,roleName,status
                });

        },

        /**
         * 删除安全分组
         * @param id
         */
        deleteSecurityGroup({id}) {
            return this.request
                .delete(`deleteRole`)
                .query({id});
        },

        /**
         * 角色列表
         * @param companyId
         * @param groupId
         */
        getFindAllRoles({companyId,groupId}) {
            return this.request
                .get(`findAllRoles`)
                .query({companyId,groupId});

        },

        /**
         * 获取角色下用户列表
         * @param roleId
         */
        getFindAllUsers({roleId}) {
            return this.request
                .get(`findAllUsers`)
                .query({roleId});

        },

        /**
         * 获取权限分组
         */
        getSecurityGroup() {
            return this.request
                .get('v1/group/getAllGroup');
        },

        /**
         * 获取权限详情
         * @param companyId
         * @param roleId
         */
        getRoleMenus(companyId, roleId) {
            return this.request
                .get('getRoleMenus')
                .query({
                    companyId,
                    roleId,
                });
        },
        
        /**
         * 获取角色菜单权限
         * @param roleId
         * @param userId
         */
        getRoleMenu(roleId, userId) {
            return this.request
                .get('/findRoleMenu')
                .query({roleId, userId});
        },

        /**
         * 登录后查看角色功能权限
         * 细分至每个页面，每个按钮
         * @param userId
         * @returns {*}
         */
        getNewRoleMenu(userId) {
            return this.request
                .get(`/findAllRole`)
                .query({userId});
        },

        /**
         * 编辑安全组全部信息
         */
        EditSecurityAllData(choiceMenuIds, companyId, dataMenuIds, describe, groupId, id, 
            mustMenuIds, roleName, status,fieldList) {
            return this.request
                .post('saveRoleAllData')
                .send(choiceMenuIds, companyId, dataMenuIds, describe, groupId, id, 
                    mustMenuIds, roleName, status,fieldList);
        },

        /**
         * 新建安全组获取安全组列表
         * @param companyId
         * @param groupId
         * @param name
         */
        getGroupByRoles({companyId, groupId,name}) {
            return this.request
                .get(`getGroupByRoles`)
                .query({companyId, groupId,name});
        },
    }
});
