const App = () => import(/* webpackChunkName: "app", webpackPrefetch: true */ '../layout/index.vue');
// 素材
const MaterialStore = () => import(/* webpackChunkName: "journey-material", webpackPrefetch: true */ '../journey/material/store/material-list.vue');
const SMSForm = () => import(/* webpackChunkName: "journey-material", webpackPrefetch: true */ '../journey/material/form/sms-material-form.vue');
const MMSForm = () => import(/* webpackChunkName: "journey-material", webpackPrefetch: true */ '../journey/material/form/mms-material-form.vue');
const CreateEmailMethods = () => import(/* webpackChunkName: "journey-material", webpackPrefetch: true */ '../journey/material/form/create-email-methods.vue');
const RichTextEmail = () => import(/* webpackChunkName: "journey-material", webpackPrefetch: true */ '../journey/material/form/rich-text-email-form.vue');
const DraggableEmail = () => import(/* webpackChunkName: "journey-material", webpackPrefetch: true */ '../journey/material/form/draggable-email-form.vue');
const TemplateEmail = () => import(/* webpackChunkName: "journey-material", webpackPrefetch: true */ '../journey/material/form/draggable-email-form.vue');
const HeaderFooterEmail = () => import(/* webpackChunkName: "journey-material", webpackPrefetch: true */ '../journey/material/store/header-footer-Email.vue');
const CreateHeaderFooter = () => import(/* webpackChunkName: "journey-material", webpackPrefetch: true */ '../journey/material/form/create-header-footer.vue');
const RichHeaderFooter = () => import(/* webpackChunkName: "journey-material", webpackPrefetch: true */ '../journey/material/form/rich-text-header-footer.vue');
// 旅程
const JourneyRecords = () => import(/* webpackChunkName: "journey", webpackPrefetch: true */ '../journey/list/journey-list.vue');
const DraggableJourney = () => import(/* webpackChunkName: "journey", webpackPrefetch: true */ '../journey/draggable-form/draggable-journey.vue');
// 表单
const QuestionnaireList = () => import(/* webpackChunkName: "questionnaire", webpackPrefetch: true */ '../journey/questionnaire/questionnaire-list.vue');
const QuestionnaireReport = () => import(/* webpackChunkName: "questionnaire", webpackPrefetch: true */ '../journey/questionnaire/questionnaire-report.vue');
const QuestionnaireForm = () => import(/* webpackChunkName: "questionnaire", webpackPrefetch: true */ '../journey/questionnaire/questionnaire-form.vue');
const QuestionnaireView = () => import(/* webpackChunkName: "questionnaire", webpackPrefetch: true */ '../journey/questionnaire/questionnaire-view.vue');
const QuestionnaireTest = () => import(/* webpackChunkName: "questionnaire", webpackPrefetch: true */ '../journey/questionnaire/questionnaire-test.vue');
const NewQuestionnaire = () => import(/* webpackChunkName: "questionnaire", webpackPrefetch: true */ '../journey/questionnaire/new-questionnaire.vue');
const NewQuestionnaireForm = () => import(/* webpackChunkName: "questionnaire", webpackPrefetch: true */ '../journey/questionnaire/new-questionnaire-form.vue');
const ReportSetting = () => import(/* webpackChunkName: "questionnaire", webpackPrefetch: true */ '../journey/questionnaire/report-setting.vue');


export default {
    path: '/journey',
    name: 'journey',
    meta: {name: 'Dmartech', auth: true},
    component: App,
    redirect: {name: 'home'},
    children: [
        // 素材
        {path: 'material', name: 'materialStore', meta: {name: 'home.journeyMaterial'}, component: MaterialStore},
        // 短信创建
        {
            path: 'material/sms/create',
            name: 'createSMS',
            meta: {auth: 'materialStore', name: 'home.createSms'},
            component: SMSForm
        },
        // 短信编辑
        {
            path: 'material/sms/edit',
            name: 'editSMS',
            meta: {auth: 'materialStore', name: 'home.editSms'},
            component: SMSForm
        },
        // 彩信创建
        {
            path: 'material/mms/create',
            name: 'createMMS',
            meta: {auth: 'materialStore', name: 'home.createMMS'},
            component: MMSForm
        },
        // 彩信编辑
        {
            path: 'material/mms/edit',
            name: 'editMMS',
            meta: {auth: 'materialStore', name: 'home.editMMS'},
            component: MMSForm
        },
        // 创建邮件素材方式选择页
        {
            path: 'material/email/create-methods',
            name: 'createEmailMethods',
            meta: {auth: 'materialStore', name: 'home.emailContent'},
            component: CreateEmailMethods
        },
        // 邮件素材创建 - 富文本编辑器
        {
            path: 'material/email/create',
            name: 'createEmail',
            meta: {auth: 'materialStore', name: 'home.newEmailContent'},
            component: RichTextEmail
        },
        // 邮件素材编辑 - 富文本编辑器
        {
            path: 'material/email/edit',
            name: 'editEmail',
            meta: {auth: 'materialStore', name: 'home.editEmailContent'},
            component: RichTextEmail
        },
        // 邮件素材编辑 - 拖拽编辑器
        {
            path: 'material/email/drag',
            name: 'draggableEmail',
            meta: {auth: 'materialStore', name: 'home.layoutEditor'},
            component: DraggableEmail
        },
        // 邮件素材编辑 - 拖拽编辑器个性区块版
        {
            path: 'material/email/drag',
            name: 'templateEmail',
            meta: {auth: 'materialStore', name: 'home.layoutEditorBlock'},
            component: TemplateEmail
        },
        // 邮件头尾部素材
        {
            path: 'material/email/header-footer',
            name: 'headerfooterEmail',
            meta: {auth: 'materialStore', name: 'home.HeaderFooterEmail'},
            component: HeaderFooterEmail
        },
        // 创建邮件头尾部素材方式选择页
        {
            path: 'material/email/header-footer-create',
            name: 'createHeaderFooter',
            meta: {auth: 'materialStore', name: 'home.NewHeaderFooter'},
            component: CreateHeaderFooter
        },
        // 编辑邮件头尾部素材
        {
            path: 'material/email/header-footer-edit',
            name: 'richHeaderFooter',
            meta: {auth: 'materialStore', name: 'home.NewHeaderFooter'},
            component: RichHeaderFooter
        },
        // 旅程
        {path: 'record/:groupId?', name: 'journeyRecords', meta: {name: 'home.journeyList'}, component: JourneyRecords},
        {
            path: 'record/draggable/:id',
            name: 'draggableJourney',
            meta: {auth: 'journeyRecords', name: 'dragJourney.draggableJourney'},
            component: DraggableJourney
        },
        // 表单
        {
            path: 'questionnaire/:id?',
            name: 'journeyFormManagement',
            meta: {name: 'home.questionnaire'},
            component: QuestionnaireList
        },
        {
            path: 'questionnaire/add/:groupId',
            name: 'newQuestionnaire',
            meta: {auth: 'journeyFormManagement',name: 'home.newQuestionnaire'},
            component: NewQuestionnaire
        },
        {
            path: 'questionnaire/edit/:groupId', // TODO url需要改  表单/新增（编辑）/分组ID/（表单id）/标准表单（金数据表单）
            name: 'newQuestionnaireForm',
            meta: {auth: 'journeyFormManagement',name: 'home.newQuestionnaire'},
            component: NewQuestionnaireForm
        },
        {
            path: 'questionnaire/report/:id',
            name: 'questionnaireReport',
            meta: {auth: 'journeyFormManagement', name: 'home.questionnaireDetails'},
            component: QuestionnaireReport
        },
        {
            path: 'questionnaire/report/:id/setting',
            name: 'reportSetting',
            meta: {auth: 'journeyFormManagement', name: 'home.reportSetting'},
            component: ReportSetting
        },

        // 接入金数据表单
        {
            path: 'questionnaire/form/:groupId/:id',
            name: 'questionnaireForm',
            meta: {auth: 'journeyFormManagement', name: 'form.jinShuJu'},
            component: QuestionnaireForm
        },
        {
            path: 'questionnaire/view/:id',
            name: 'questionnaireView',
            meta: {auth: 'journeyFormManagement', name: 'form.jinShuJu'},
            component: QuestionnaireView
        },
        {
            path: 'questionnaire/test/:id',
            name: 'questionnaireTest',
            meta: {auth: 'journeyFormManagement', name: 'form.jinShuJu'},
            component: QuestionnaireTest
        }
    ]
};
