var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search-tree" },
    [
      _c("search", {
        attrs: {
          position: false,
          treeData: _vm.searchTreeData,
          lastStage: _vm.levelStage,
        },
        on: { sureSearch: _vm.sureSearch },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "tree-menu-wrapper" },
        _vm._l(_vm.searchTreeData, function (menuItem, index) {
          return _c(
            "ul",
            { key: index },
            [
              _c("tree-menu", {
                attrs: {
                  groupData: menuItem,
                  depth: 0,
                  action: false,
                  activeId: _vm.treeActiveId,
                  levelStage: _vm.levelStage,
                  moduleRange: _vm.moduleRange,
                },
                on: { choose: _vm.chooseItem },
              }),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }