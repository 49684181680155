var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("backdrop", [
    _c("div", { staticClass: "modal", class: _vm.size }, [
      _c("div", { staticClass: "modal-head" }, [
        _c(
          "button",
          {
            staticClass: "pull-right modal-closer",
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.close.apply(null, arguments)
              },
            },
          },
          [
            !_vm.readonly
              ? _c("svg", { attrs: { "aria-hidden": "true" } }, [
                  _c("use", { attrs: { "xlink:href": "#icon-close" } }),
                ])
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _c("span", { attrs: { title: _vm.title } }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-content", class: { neat: _vm.neat } },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _vm.$slots.buttons
        ? _c("div", { staticClass: "modal-panel" }, [_vm._t("buttons")], 2)
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }