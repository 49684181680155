var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "left-sidebar" },
    [
      _vm.fixedList.length > 0
        ? _c(
            "ul",
            { staticClass: "fixed-menu" },
            _vm._l(_vm.fixedList, function (fixedGroup, index) {
              return _c(
                "li",
                {
                  key: index,
                  class: {
                    otherActive:
                      fixedGroup.id === _vm.fixedActiveId && _vm.fixedActive,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.selectFixed(fixedGroup)
                    },
                  },
                },
                [
                  _c("span", { staticClass: "notice-box" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(fixedGroup.name) +
                        "\n                "
                    ),
                    fixedGroup.name === _vm.$t("journeyList.approval") &&
                    _vm.showNotice
                      ? _c("span", { staticClass: "notice" })
                      : _vm._e(),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("search", {
            attrs: {
              position: true,
              treeData: _vm.SideTreeData,
              lastStage: true,
            },
            on: { sureSearch: _vm.sureSearch },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "module-group" }, [
        _c("span", { staticClass: "module-name" }, [
          _vm._v(_vm._s(_vm._f("t")("common.allGroups"))),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "action-module" },
          [
            _c("selector", {
              staticClass: "icon-size titlesas-sort",
              attrs: {
                "auto-width": "",
                icon: "sort",
                shortcutAction: false,
                options: _vm.orderOptions,
              },
              on: { select: _vm.gainList },
              model: {
                value: _vm.orderBy,
                callback: function ($$v) {
                  _vm.orderBy = $$v
                },
                expression: "orderBy",
              },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "add-action-btn",
                attrs: { type: "button", disabled: _vm.$has(_vm.addGroup) },
                on: { click: _vm.outAddLayer },
              },
              [
                _c("svg", { attrs: { "aria-hidden": "true" } }, [
                  _c("use", { attrs: { "xlink:href": "#icon-plus" } }),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.SideTreeData, function (menuItem) {
        return _c(
          "ul",
          { key: menuItem.id },
          [
            _c("tree-menu", {
              attrs: {
                groupData: menuItem,
                depth: 0,
                action: true,
                activeId: _vm.activeId,
                levelStage: true,
                displayLevel: _vm.displayLevel,
                moduleRange: _vm.moduleRange,
              },
              on: { choose: _vm.chooseItem },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.createEditShow
        ? _c(
            "modal",
            {
              attrs: {
                title:
                  _vm.optionType === "create"
                    ? _vm.$t("common.newGroup")
                    : _vm.$t("common.editGroup"),
              },
              on: { close: _vm.cancelEditNew },
              scopedSlots: _vm._u(
                [
                  {
                    key: "buttons",
                    fn: function () {
                      return [
                        _c("input", {
                          staticClass: "btn btn-md btn-white",
                          attrs: {
                            type: "button",
                            value: _vm.$t("common.cancel"),
                          },
                          on: { click: _vm.cancelEditNew },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          staticClass: "btn btn-md btn-theme",
                          attrs: {
                            type: "button",
                            value: _vm.$t("common.confirm"),
                          },
                          on: { click: _vm.sureEditNew },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                2346908845
              ),
            },
            [
              _c("div", { staticClass: "form-field" }, [
                _c(
                  "label",
                  { staticClass: "label", attrs: { for: "groupText" } },
                  [_vm._v(_vm._s(_vm._f("t")("common.groupName")))]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.groupName,
                      expression: "groupName",
                      modifiers: { trim: true },
                    },
                  ],
                  staticClass: "input lg",
                  attrs: {
                    type: "text",
                    id: "groupText",
                    placeholder: _vm.$t("common.groupName"),
                    maxlength: "255",
                  },
                  domProps: { value: _vm.groupName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.groupName = $event.target.value.trim()
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-field" }, [
                _c(
                  "label",
                  { staticClass: "label placeholder", attrs: { for: "pId" } },
                  [_vm._v(_vm._s(_vm._f("t")("common.parentGroup")))]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "choose-parent lg",
                    attrs: { id: "pId" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.readyCheckGroup.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("label", { staticClass: "select-text" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.superiorGroupName) +
                          "\n                    "
                      ),
                      _c("span", {
                        staticClass: "select-icon icon icon-arrow-down",
                      }),
                    ]),
                    _vm._v(" "),
                    _vm.groupSelectShow
                      ? _c(
                          "div",
                          {
                            staticClass: "search-tree-wrapper",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c("search-tree", {
                              attrs: {
                                searchTreeData: _vm.allTreeData,
                                treeActiveId: _vm.treeActiveId,
                                action: false,
                                levelStage: false,
                                moduleRange: _vm.moduleRange,
                              },
                              on: { getTreeAct: _vm.getTreeAct },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.delShow
        ? _c(
            "modal",
            {
              attrs: { title: _vm.$t("common.delGroup") },
              on: {
                close: function ($event) {
                  _vm.delShow = false
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "buttons",
                    fn: function () {
                      return [
                        _vm.groupInformation.groupData.childs.length === 0
                          ? _c("div", [
                              _c("input", {
                                staticClass: "btn btn-md btn-white",
                                attrs: {
                                  type: "button",
                                  value: _vm.$t("common.cancel"),
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.delShow = false
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("input", {
                                staticClass: "btn btn-md btn-theme",
                                attrs: {
                                  type: "button",
                                  value: _vm.$t("common.confirm"),
                                },
                                on: { click: _vm.sureDelGroup },
                              }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.groupInformation.groupData.childs.length !== 0
                          ? _c("input", {
                              staticClass: "btn btn-md btn-theme",
                              attrs: {
                                type: "button",
                                value: _vm.$t("common.confirm"),
                              },
                              on: {
                                click: function ($event) {
                                  _vm.delShow = false
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1633857488
              ),
            },
            [
              _vm.groupInformation.groupData.childs.length === 0
                ? _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("t")("common.confirmDelFir")) +
                        " " +
                        _vm._s(_vm.groupName) +
                        " " +
                        _vm._s(_vm._f("t")("common.confirmDelSed"))
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.groupInformation.groupData.childs.length !== 0
                ? _c("span", [_vm._v(_vm._s(_vm._f("t")("common.UnableDel")))])
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }