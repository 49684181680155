
<template>
    <modal :title="title ? title : $t('common.defaultConfirmTitle')" @close="cancel" :size="size">
        <slot></slot>
        <template v-slot:buttons>
            <input type="button" class="btn btn-md btn-theme-border" @click.stop="cancel"
                   :value="$t('common.cancel')"/>
            <input type="button" class="btn btn-md btn-theme" tabindex="1" autofocus @click.stop="sure"
                   :value="$t('common.confirm')"/>
        </template>
    </modal>
</template>
<script type="text/ecmascript-6">
    export default {
        name: 'confirm',
        props: {
            title: {
                type: String,
            },
            size: String,
            // 自动focus回车确认
            autoConfirm: {
                type: Boolean,
                default: true
            }
        },
        mounted() {
            window.addEventListener('keydown', this.keyDown, false);
        },
        beforeDestroy() {
            window.removeEventListener('keydown', this.keyDown, false);
        },
        methods: {
            keyDown(e) {
                if (e.keyCode === 13 && this.autoConfirm === true) {
                    e.preventDefault();
                    e.stopPropagation();
                    this.sure();
                }
            },
            cancel() {
                this.$emit('cancel');
            },
            sure() {
                this.$emit('confirm');
            }
        }
    };
</script>
