<template>
    <loading v-if="loading"/>
    <div v-else class="material-preview-wrapper">
        <ul class="device" v-if="toolbar && (material.type === 1 || material.type === 3 || material.type === 6 || material.isHF)">
            <li class="pc" :class="{active: device === 'pc'}" v-title:top="$t('material.pcPreview')"
                @click="device = 'pc'">
                <i class="icon icon-pc"></i>
            </li>
            <li class="mobile" :class="{active: device === 'mobile'}" v-title:top="$t('material.mobilePreview')"
                @click="device = 'mobile'">
                <i class="icon icon-mobile"></i>
            </li>
        </ul>
        <iframe v-if="material.type === 1 || material.type === 3 || material.type === 6 || material.isHF"
                class="email-preview-frame"
                :class="device"
                :srcdoc="content"
                ref="emailPreviewFrame"></iframe>
        <!-- material.type 可能是字符串 -->
        <div v-if="material.type == 0" class="sms-preview-frame">
            <div class="sms-preview-frame-content">
                <!-- comments necessary cuz white spaces are visible -->
                <div class="sms-preview-bubble"><!--
                    -->{{signature ? '【' + signature + '】' : ''}}<!--
                    --> {{UNSUBSCRIBE_SMS_POSTFIX_REGEXP.test(content) ? content : content + UNSUBSCRIBE_SMS_POSTFIX}}<!--
                    --><i class="bubble-tail"></i><!--
                --></div>
            </div>
        </div>
        <div v-if="material.type === 2 && !material.isHF" class="mms-modal-preview-frame">
            <div class="mms-modal-preview">
                <!--素材列表-预览-->
                <template v-if="material.otherCount && material.otherCount.mmsContent">
                    <div v-for="(mms,index) in material.otherCount.mmsContent" :key="mms.id">
                        <p>
                            <img :src="mms.pictureUrl" v-if="mms.pictureUrl">
                            <span v-text="sampleName ? randMmsContents[index] : mms.mmsContent">{{mms.mmsContent}}</span>
                            <span v-if="index == material.otherCount.mmsContent.length-1">
                                {{UNSUBSCRIBE_SMS_POSTFIX}}
                            </span>
                        </p>
                    </div>
                </template>
                <!--素材编辑-预览-->
                <template v-else>
                    <div v-for="(mms,index) in material.materialMms" :key="mms.id">
                        <p>
                            <img :src="mms.pictureUrl" v-if="mms.pictureUrl">
                            <span v-text="sampleName ? randMmsContents[index] : mms.mmsContent">{{mms.mmsContent}}</span>
                            <span v-if="index == material.materialMms.length-1">
                                {{UNSUBSCRIBE_SMS_POSTFIX}}
                            </span>
                        </p>
                    </div>
                </template>
            </div>
        </div>
        <wechat-preview v-if="material.type === 5" :previewItem="material"></wechat-preview>
        <ul v-if="toolbar && (material.type === 0 || material.type === 1 || material.type === 3 || material.type === 2 || material.type === 6) && !material.isHF"
            class="random-test-wrapper">
            <li class="random"
                v-title:left="$t('material.updateSample')+`<br>`+$t('material.current')+`:${sampleName}]`"
                @click="getRandomField()">
                <i class="icon icon-touzi"></i>
            </li>
            <li class="test" v-title:left="$t('material.testSend')" @click="$test(material,material.testSubject ? material.testSubject : '')"><i
                    class="icon icon-flask"></i></li>
        </ul>
        <!-- 微信模板 -->
        <div class="wechat-template-preview" v-if="material.type === 8">
            <div class="wechat-template">
                <div v-for="list in (material.sendContentArr || material.example)" v-html="list"></div>
            </div>
            <!--<p class="light-content-text tar">更新时间：{{material.updateDate}}</p>-->
        </div>
    </div>
</template>
<script type="text/ecmascript-6">
    import debounce from 'lodash/debounce';
    import max from 'lodash/max';
    import WechatPreview from './wechat-preview.vue';
    import materialModel from './materialModel';
    import {UNSUBSCRIBE_SMS_POSTFIX} from './form/sms-material-form.vue';

    /**
     * material.type
     * 0 短信素材
     * 1 邮件素材，
     * 2 彩信素材
     * 3 拖拽编辑器生成的邮件素材
     * 4
     * 5 微信素材
     * 6 个性化区块素材
     * 7
     * 8
     */
    export default {
        props: {
            material: {
                type: Object,
                required: true
            },
            signature: {
                type: String,
                'default': ''
            },
            loading: false,
            toolbar: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                UNSUBSCRIBE_SMS_POSTFIX,
                device: 'pc',
                sampleName: '',
                content: '',
                randMmsContents: [],// 彩信随机样例
            };
        },
        computed: {
            UNSUBSCRIBE_SMS_POSTFIX_REGEXP() {
                return new RegExp(this.UNSUBSCRIBE_SMS_POSTFIX + '$');
            },
            wechatTemplateSendContentArr() {
                if (this.material.type === 8 && this.material) {
                    return this.material.content
                        .map(({content, sendContent}) => {
                            let color = '', text = '';
                            const match = /^(.*){{(.+)\..+}}$/.exec(content);
                            if (match) {
                                text += match[1];
                                let key = match[2];
                                let targetContent = sendContent.find(sc => sc[key]);
                                if (targetContent) {
                                    color = targetContent[key].color;
                                    text += targetContent[key].value;
                                }
                            }
                            return `<span style="color: ${color};">${text}</span>`;
                        });
                }
                return [];
            }
        },
        mounted() {
            if (this.material.type == 5 || this.material.isHF) {
                this.content = this.material.content;
            } else {
                this.content = this.material.materialContent;
            }
        },
        watch: {
            'material.content'() {
                this.content = this.material.content;
            },
            'material.materialContent'() {
                this.content = this.material.materialContent;
            },
            content() {
                if (this.$refs.emailPreviewFrame) this.scaleEmailContent();
            },
            device() {
                if (this.$refs.emailPreviewFrame) this.scaleEmailContent();
            }
        },
        methods: {
            makeLinksTargetOuter(htmlStr) {
                let resultStr = htmlStr.replace(/target=["'][a-z_]*["']/ig, '');
                resultStr = resultStr.replace(/(<a[^>]*)(>)/ig, '$1 target="_blank">');
                resultStr = resultStr.replace(/(<a[^>]*)(>)/ig, (arg1) => {
                    if (arg1.indexOf('href="mailto:') > 0) {
                        return arg1.replace('target="_blank"', 'target=""');
                    } else {
                        return arg1;
                    }
                });
                return resultStr;
            },
            getRandomField() {
                materialModel
                    .sampleMaterial(this.material.id)
                    .then(({body: {data: {materialContent, sampleName, mmsContents}}}) => {
                        this.sampleName = sampleName;
                        this.content = materialContent;
                        this.randMmsContents = mmsContents;
                    });
            },
            rep(list) {
                list = list.replace(/<(span.*?)(style.*?)>/g, '');
                list = list.replace(/<\/span>/g, '');
                return list;
            },
            scaleEmailContent: debounce(function () {
                const frame = this.$refs.emailPreviewFrame;
                const containerWidth = frame.contentDocument.documentElement.clientWidth - 16; // 16 是留一点间隙
                const contentHeight = frame.contentDocument.body.clientHeight;
                const realContentWidth = max(Array.from(frame.contentDocument.body.childNodes, node => node.clientWidth));
                const contentWidth = realContentWidth || 359;
                const scale = (containerWidth / contentWidth).toFixed(6);
                if (this.device === 'mobile') {
                    frame.contentDocument.documentElement.style = `height: ${contentHeight * scale + 16}px;padding: 8px;`;
                    frame.contentDocument.body.style = `width:${contentWidth}px;margin:0 auto;transform: scale(${scale}, ${scale});transform-origin: 0 0 0;`;
                } else {
                    frame.contentDocument.body.style = '';
                    frame.contentDocument.documentElement.style = '';
                }
            }, 100, {trailing: true})
        },
        components: {
            WechatPreview
        }
    };
</script>
<style lang="scss" type="text/scss" rel="stylesheet/scss">

    @import "../../common/scss/base/variables";
    @import "../../common/scss/base/mixin";

    .material-preview-wrapper {
        position: relative;
        overflow: hidden;
        min-height: 300px;
        max-height: 100%;

        $device-height: $input-field-height + 40px;

        .device {
            display: flex;
            width: 91px;
            height: 40px - 2px;
            margin: ($input-field-height)/2 auto;
            line-height: 40px - 2px;
            font-size: 0;
            text-align: center;
            border-radius: 4px;
            overflow: hidden;
            border: 1px solid $border-color;

            li {
                flex: 1;
                height: 100%;
                font-size: 30px;
                color: $color-light-content;
                cursor: pointer;

                &.active {
                    color: $orange;
                    background: #f1f1f1;
                }

                &:first-of-type {
                    border-right: 1px solid $border-color;
                }
            }

            + .email-preview-frame {
                height: calc(70vh - #{$device-height + $input-field-height});
            }
        }

        .email-preview-frame {
            display: block;
            position: relative;
            height: 100%;

            &.pc {
                width: 100%;
                border: none;
            }

            &.mobile {
                width: 375px;
                margin: 0 auto;
                border: 2px solid $border-color;
                border-radius: 4px;
            }
        }

        .random-test-wrapper {
            position: absolute;
            right: 30px;
            top: 64px;
            z-index: 1;
            width: 42px;
            height: 98px;
            cursor: pointer;
            li {
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: 26px;
                border-radius: 5px;
                border: 1px solid $border-color;
                color: $color-light-content;
                &:hover {
                    border-color: $orange;
                    color: $orange;
                    background: $content-bg;
                }

                &.random {
                    margin-bottom: 14px;
                }
            }
        }

        .sms-preview-frame {
            position: relative;
            overflow: hidden;
            margin: 20px auto;
            width: 270px;
            height: 450px;
            text-align: left;
            background: url("sms-preview-frame.png") no-repeat center;
            @include background-size(contain);

            .sms-preview-frame-content {
                position: absolute;
                overflow: auto;
                left: 55px;
                right: -17px;
                top: 130px;
                bottom: 80px;
            }
        }

        .sms-preview-bubble {
            position: relative;
            @include box-sizing();
            width: 155px;
            min-height: 6em;
            padding: 1em;
            @include border-radius(15px);
            background-color: #e5e3e3;
            word-break: break-all;
            white-space: pre-wrap;
            line-height: normal;

            .bubble-tail {
                position: absolute;
                bottom: -5px;
                left: -8px;
                display: block;
                width: 23px;
                height: 15px;
                overflow: hidden;

                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: -30px;
                    display: block;
                    width: 60px;
                    height: 60px;
                    background: #e5e3e3;
                    border-radius: 50%;
                }

                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: -10px;
                    display: block;
                    width: 20px;
                    height: 20px;
                    background: #fff;
                    border-radius: 50%;
                }
            }
        }

        .mms-modal-preview-frame {
            position: relative;
            top: -60px;
            margin: 0 auto;
            width: 400px;
            height: 770px;
            text-align: left;
            background: url("../material/form/images/phone-mms.png") no-repeat center center;

            .mms-modal-preview {
                position: absolute;
                width: 62%;
                height: 350px;
                top: 245px;
                left: 75px;
                overflow-y: auto;
                overflow-x: hidden;

                div {
                    position: relative;
                    left: 2px;
                    max-width: 100%;
                    margin: 0 0 10px 0;

                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        background: url("../material/form/images/phone-text-mms.png") no-repeat;
                        width: 13px;
                        height: 15px;
                    }

                    img {
                        width: 95%;
                        height: auto;
                        margin: 0 2% 10px 3%;
                        display: block;
                    }
                    p {
                        position: relative;
                        padding: 10px 20px;
                        margin: 5px;
                        line-height: 22px;
                        border-radius: 17px;
                        font-size: 12px;
                        background-color: #e5e3e3;
                        word-break: break-all;
                        max-width: 98%;
                    }
                }
            }

        }

        .wechat-template-preview {
            width: 320px;
            margin: auto;
            padding: 16px 0 32px;
            line-height: $input-field-height;

            .wechat-template {
                min-height: 200px;
                padding: 16px;
                border: 1px solid $border-color;
                border-radius: 4px;
                background-color: $content-bg;
            }
        }
    }

</style>
