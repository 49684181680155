const App = () => import(/* webpackChunkName: "app", webpackPrefetch: true */ '../layout/index.vue');
// 数据中心
const DataCenter = () => import(/* webpackChunkName: "data-center", webpackPrefetch: true */ '../data/data-center/data-center.vue');
const DataAccess = () => import(/* webpackChunkName: "data-center", webpackPrefetch: true */ '../data/data-center/components/data-access.vue');
const DataFilesSftp = () => import(/* webpackChunkName: "data-center", webpackPrefetch: true */ '../data/data-center/sftp/sftp.vue');
const sftpDetails = () => import(/* webpackChunkName: "data-center", webpackPrefetch: true */ '../data/data-center/sftp/sftp-details.vue');
const sdk = () => import(/* webpackChunkName: "data-center", webpackPrefetch: true */ '../data/data-center/service-sdk/sdk.vue');
const jsSdk = () => import(/* webpackChunkName: "data-center", webpackPrefetch: true */ '../data/data-center/js-sdk/js-sdk.vue');
const jsSdkDetails = () => import(/* webpackChunkName: "data-center", webpackPrefetch: true */ '../data/data-center/js-sdk/js-sdk-details.vue');
const sdkDetails = () => import(/* webpackChunkName: "data-center", webpackPrefetch: true */ '../data/data-center/service-sdk/sdk-details.vue');
const api = () => import(/* webpackChunkName: "data-center", webpackPrefetch: true */ '../data/data-center/api/api.vue');
const apiDetails = () => import(/* webpackChunkName: "data-center", webpackPrefetch: true */ '../data/data-center/api/api-details.vue');
// 元数据
const MetaData = () => import(/* webpackChunkName: "meta", webpackPrefetch: true */ '../data/meta-data/meta-data.vue');
const EventGroup = () => import(/* webpackChunkName: "meta", webpackPrefetch: true */ '../data/meta-data/event-group/event-group.vue');
const MetaEvent = () => import(/* webpackChunkName: "meta", webpackPrefetch: true */ '../data/meta-data/meta-event/meta-event.vue');
const UserAttribute = () => import(/* webpackChunkName: "meta", webpackPrefetch: true */ '../data/meta-data/attribute/user-attributes.vue');
const EventAttribute = () => import(/* webpackChunkName: "meta", webpackPrefetch: true */ '../data/meta-data/attribute/event-attribute.vue');
const ScoreList = () => import(/* webpackChunkName: "score", webpackPrefetch: true */ '../data/meta-data/score-evaluation/score-list.vue');
// 标签管理
const TagManage = () => import(/* webpackChunkName: "tagManage", webpackPrefetch: true */'../data/tag/tag-manage.vue');
// 报告
const dataReport = () => import(/* webpackChunkName: "report", webpackPrefetch: true */'../data/report/customer/customer-report.vue');
const orderReport = () => import(/* webpackChunkName: "report", webpackPrefetch: true */'../data/report/order/order-report.vue');
const WeChatReport = () => import(/* webpackChunkName: "report", webpackPrefetch: true */'../data/report/wechat/wechat-report.vue');
const AppAnalysis = () => import(/* webpackChunkName: "journey-report", webpackPrefetch: true */ '../data/report/wechat/app-analysis.vue');
const FollowerAnalysis = () => import(/* webpackChunkName: "journey-report", webpackPrefetch: true */ '../data/report/wechat/follower-analysis.vue');
const JourneyReport = () => import(/* webpackChunkName: "journey-report", webpackPrefetch: true */ '../data/report/journey/report.vue');
const JourneyReviewReport = () => import(/* webpackChunkName: "journey-report", webpackPrefetch: true */ '../data/report/journey/journey-review-report/journey-review-report.vue');
const JourneySendReport = () => import(/* webpackChunkName: "journey-report", webpackPrefetch: true */ '../data/report/journey/journey-send-report/journey-send-report.vue');
const EmailSendReport = () => import(/* webpackChunkName: "journey-report", webpackPrefetch: true */ '../data/report/journey/journey-send-report/email-send-report.vue');
const SmsSendReport = () => import(/* webpackChunkName: "journey-report", webpackPrefetch: true */ '../data/report/journey/journey-send-report/sms-send-report.vue');
const WechatSendReport = () => import(/* webpackChunkName: "journey-report", webpackPrefetch: true */ '../data/report/journey/journey-send-report/wechat-send-report.vue');
const JourneyExportReport = () => import(/* webpackChunkName: "journey-report", webpackPrefetch: true */ '../data/report/journey/journey-export-report/journey-export-report.vue');
const JourneyReportNike = () => import(/* webpackChunkName: "journey-report", webpackPrefetch: true */ '../data/report/journey/journey-export-report/nike-report.vue');
const ExportOriginalData = () => import(/* webpackChunkName: "journey-report", webpackPrefetch: true */ '../data/report/journey/journey-export-report/original-data.vue');
const ExportSendRecord = () => import(/* webpackChunkName: "journey-report", webpackPrefetch: true */ '../data/report/journey/journey-export-report/send-record.vue');
const EmailJourneyCompared = () => import(/* webpackChunkName: "journey-report", webpackPrefetch: true */ '../data/report/journey/journey-send-report/email-journey-compared.vue');
const EmailReportNike = () => import(/* webpackChunkName: "journey-report", webpackPrefetch: true */ '../data/report/journey/journey-export-report/email-report-nike.vue');
const EmailReportDataNike = () => import(/* webpackChunkName: "journey-report", webpackPrefetch: true */ '../data/report/journey/journey-export-report/email-reportData-nike.vue');
const EmailReportDetailsNike = () => import(/* webpackChunkName: "journey-report", webpackPrefetch: true */ '../data/report/journey/journey-export-report/email-reportDetails-nike.vue');
/**
 * 数据中心
 */
export default {
    path: '/data',
    name: 'data',
    meta: {name: 'Dmartech', auth: true},
    component: App,
    redirect: {name: 'home'},
    children: [
        // 数据中心
        {path: 'center', name: 'dataCenter', meta: {name: 'home.data'}, component: DataCenter},
        {
            path: 'center/sftp',
            name: 'sftpDetails',
            meta: {auth: 'dataCenter', name: 'dataCenter.sftpAccess'},
            component: sftpDetails
        },
        {
            path: 'center/sdk',
            name: 'sdkDetails',
            meta: {auth: 'dataCenter', name: 'dataCenter.severSDKAccess'},
            component: sdkDetails
        },
        {
            path: 'center/js-sdk',
            name: 'jsSdkDetails',
            meta: {auth: 'dataCenter', name: 'dataCenter.jsSDKAccess'},
            component: jsSdkDetails
        },
        {
            path: 'center/api',
            name: 'apiDetails',
            meta: {auth: 'dataCenter', name: 'dataCenter.api'},
            component: apiDetails
        },
        // 数据接入
        {
            path: 'dataAccess',
            name: 'dataAccess',
            meta: {auth: 'dataCenter', name: 'dataCenter.dataAccess'},
            component: DataAccess,
            children: [
                {
                    path: 'sftp',
                    name: 'sftp',
                    meta: {auth: 'dataCenter', name: 'dataCenter.sftpAccess'},
                    component: DataFilesSftp
                },
                {
                    path: 'sdk',
                    name: 'sdk',
                    meta: {auth: 'dataCenter', name: 'dataCenter.severSDKAccess'},
                    component: sdk,
                },
                {
                    path: 'js-sdk',
                    name: 'jsSdk',
                    meta: {auth: 'dataCenter', name: 'dataCenter.jsSDKAccess'},
                    component: jsSdk,
                },
                {path: 'api', name: 'api', meta: {auth: 'dataCenter', name: 'dataCenter.api'}, component: api,},
            ]
        },
        // 元数据
        {
            path: 'meta',
            name: 'meta',
            meta: {name: 'home.meta'},
            component: MetaData,
            redirect: {name: 'eventGroup'},
            children: [
                {
                    path: 'event-group',
                    name: 'eventGroup',
                    meta: {auth: 'meta', name: 'metaData.eventGroup'},
                    component: EventGroup
                },
                {
                    path: 'meta-event',
                    name: 'metaEvent',
                    meta: {auth: 'meta', name: 'metaData.eventMgt'},
                    component: MetaEvent
                },
                {
                    path: 'user-attribute',
                    name: 'userAttribute',
                    meta: {auth: 'meta', name: 'metaData.userAttr'},
                    component: UserAttribute
                },
                {
                    path: 'event-attribute',
                    name: 'eventAttribute',
                    meta: {auth: 'meta', name: 'metaData.eventAttr'},
                    component: EventAttribute
                },
                {
                    path: 'score-list',
                    name: 'scoreList',
                    meta: {auth: 'meta', name: 'metaData.scoreEva'},
                    component: ScoreList
                }
            ]
        },
        // 标签管理
        {
            path: 'tagManage',
            name: 'tagManage',
            meta: {name: 'home.tag'},
            component: TagManage,
        },
        // 客户报告
        {
            path: 'report/customer',
            name: 'dataReport',
            meta: {name: 'home.customerReport'},
            component: dataReport
        },
        // 订单报告
        {
            path: 'report/order',
            name: 'orderReport',
            meta: {name: 'home.orderReport'},
            component: orderReport
        },
        // 微信报告
        {
            path: 'report/wechat',
            meta: {name: 'home.wechatReport'},
            name: 'WeChat',
            component: WeChatReport,
            redirect: {name: 'appAnalysis'},
            children:[
                {
                    path: 'App-Analysis',
                    name: 'appAnalysis',
                    meta: {auth: 'WeChat', name: 'home.appAnalysis'},
                    component: AppAnalysis,
                },
                {
                    path: 'Follower-Analysis',
                    name: 'followerAnalysis',
                    meta: {auth: 'WeChat', name: 'home.followerAnalysis'},
                    component: FollowerAnalysis,
                }
            ]
        },
        // 旅程报告
        {
            path: 'report/journey',
            name: 'journeyReport',
            meta: {name: 'home.journeyReport'},
            component: JourneyReport,
            redirect: {name: 'journeyReviewReport'},
            children: [
                {
                    path: 'review-report',
                    name: 'journeyReviewReport',
                    meta: {auth: 'journeyReport', name: 'data.journeyReview'},
                    component: JourneyReviewReport
                },
                {
                    path: 'send-report',
                    name: 'journeySendReport',
                    meta: {auth: 'journeyReport', name: 'data.reportList'},
                    component: JourneySendReport,
                },
                {
                    path: 'send-report/email-send-report',
                    name: 'emailSendReport',
                    meta: {auth: 'journeyReport',name: 'data.emailSendReport'},
                    component: EmailSendReport,
                },
                {
                    path: 'send-report/sms-send-report',
                    name: 'smsSendReport',
                    meta: {auth: 'journeyReport',name: 'data.smsSendReport', type: 0},
                    component: SmsSendReport
                },
                {
                    path: 'send-report/mms-send-report',
                    name: 'mmsSendReport',
                    meta: {auth: 'journeyReport',name: 'data.mmsSendReport', type: 1},
                    component: SmsSendReport
                },
                {
                    path: 'send-report/wechat-send-report',
                    name: 'wechatSendReport',
                    meta: {auth: 'journeyReport',name: 'data.wechatSendReport'},
                    component: WechatSendReport
                },
                {
                    path: 'export-report',
                    name: 'journeyExportReport',
                    meta: {auth: 'journeyReport', name: 'data.exportDatas'},
                    component: JourneyExportReport,
                    redirect: {name: 'exportOriginalData'},
                    children: [
                        {
                            path: 'original-data',
                            name: 'exportOriginalData',
                            meta: {auth: 'journeyReport',name: 'data.exportOriginalData'},
                            component: ExportOriginalData,
                        },
                        {
                            path: 'send-record',
                            name: 'exportSendRecord',
                            meta: {auth: 'journeyReport',name: 'data.exportSendRecord'},
                            component: ExportSendRecord
                        },
                    ]
                },
                {
                    path: 'send-report/email-journey-compared',
                    name: 'emailJourneyCompared',
                    meta: {auth: 'journeyReport',name: 'data.emailSendReport'},
                    component: EmailJourneyCompared
                }
            ]
        },
        {
            path: 'journey/report/email',
            name: 'emailreport',
            meta: {auth: 'journeyReport', name: 'data.emailReport'},
            component: JourneyReportNike
        },
        //邮件报告（nike）
        {
            path: '/report/email-report-nike',
            name: 'emailReportNike',
            meta: {auth: 'emailReportNike',name: 'data.emailReportNike'},
            component: EmailReportNike,
            redirect: {name: 'emailReportDataNike'},
            children:[
                {
                    path: 'label',
                    name: 'emailReportDataNike',
                    meta: {auth: 'emailReportNike',name: 'data.emailMailingLabel'},
                    component: EmailReportDataNike,
                },
                {
                    path: 'details',
                    name: 'emailReportDetailsNike',
                    meta: {auth: 'emailReportNike',name: 'data.emailReportDetailsNike'},
                    component: EmailReportDetailsNike
                }
            ]
        },
    ]
};
